/* eslint-disable @typescript-eslint/no-unused-vars */
import { createTheme } from '@mui/material/styles';
import buttons from './button';
import { dialog, dialogActions, dialogContent, dialogTitle } from './dialog';
import { divider } from './divider';
import { palette } from './palette';
import { typography } from './typography';
import { card, cardMedia, cardContent, cardActions } from './companyCard';
import { inputBase, outlinedInput, inputLabel } from './input';
import { listItem, listItemText, listItemIcon } from './menu';

const theme = createTheme({
	palette,
	components: {
		MuiButton: buttons,
		MuiDivider: divider,
		MuiTypography: typography,
		// Dialog
		MuiDialog: dialog,
		MuiDialogTitle: dialogTitle,
		MuiDialogContent: dialogContent,
		MuiDialogActions: dialogActions,
		// Card
		MuiCard: card,
		MuiCardMedia: cardMedia,
		MuiCardContent: cardContent,
		MuiCardActions: cardActions,
		// Input
		MuiInputBase: inputBase,
		MuiOutlinedInput: outlinedInput,
		MuiInputLabel: inputLabel,
		// Menu
		MuiListItem: listItem,
		MuiListItemText: listItemText,
		MuiListItemIcon: listItemIcon,
	},
});

export default theme;

import theme from 'theme';
const { spacing } = theme;

export const styles = `
  .title {
    margin-bottom: ${spacing(2)};
  }
  .rule {
    display: flex;
    gap: ${spacing(2)};
    margin-bottom: ${spacing(0.5)};
  }
  .icon {
    font-size: 1.5rem;
  }
  .label {
    font-size: ${spacing(2)};
    font-weight: 300;
  }
`;

import * as colors from '@mui/material/colors';
import theme from 'theme';

type Shade = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
interface Color {
	[key: string]: string;
}

export const stringToColor = (name: string, shade: Shade = 500): string => {
	const newColorList: string[] = Object.values(colors).map(
		(color: Color): string => color[shade]
	); // Use only the selected shade

	let hash = 0;

	if (name?.length) {
		for (let i = 0; i < name.length; i++) {
			hash = name.charCodeAt(i) + ((hash << 5) - hash);
			hash = hash & hash;
		}

		hash = ((hash % newColorList.length) + newColorList.length) % newColorList.length;

		return newColorList[hash];
	}

	return theme.palette.primary.main;
};

export default stringToColor;

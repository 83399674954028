import { ReactNode } from 'react';
import {
	Dialog as MuiDialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Divider,
} from '@mui/material';
import { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonProps } from '@mui/material/Button';
import Button, { IButton } from 'components/button/Button';

interface DialogProps extends MuiDialogProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	children: ReactNode;
	hideDividers?: boolean;
	actions?: Array<ButtonProps & IButton>;
}

const Dialog = ({
	onClose,
	title,
	children,
	hideDividers = false,
	actions,
	...rest
}: DialogProps) => {
	return (
		<MuiDialog onClose={onClose} fullWidth {...rest}>
			<DialogTitle id="dialog-title">
				{title ?? <span></span>}
				<IconButton aria-label="close" onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{hideDividers || !title ? null : <Divider />}
			<DialogContent>{children}</DialogContent>
			{hideDividers ? null : <Divider />}
			{actions ? (
				<DialogActions>
					{actions.map((buttonProps, index) => (
						<Button key={`action-button-${index}`} {...buttonProps} />
					))}
				</DialogActions>
			) : null}
		</MuiDialog>
	);
};

export default Dialog;

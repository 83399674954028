import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, InputAdornment, Stack } from '@mui/material';
import { LockOpenOutlined as LockOpenOutlinedIcon } from '@mui/icons-material';
import { Panel, PasswordMatching, PasswordValidator, TextInput } from 'components';
import { useMutation } from 'react-query';
import { changePassword } from 'queries';

interface ChangePasswordForm {
	oldPassword: string;
	newPassword: string;
	repeatPassword: string;
}

const ValidationSchema = Yup.object().shape({
	oldPassword: Yup.string()
		.min(2, 'Muy corto')
		.max(30, 'Muy Largo')
		.required('Requerido'),
	newPassword: Yup.string()
		.min(2, 'Muy corto')
		.max(30, 'Muy Largo')
		.required('Requerido'),
	repeatPassword: Yup.string()
		.min(2, 'Muy corto')
		.max(30, 'Muy Largo')
		.required('Requerido'),
});

const ResetPassword = () => {
	const passwordMutation = useMutation(changePassword, {
		onSuccess: res => {
			// TODO: Add some positive feedback
		},
	});

	const onSubmit = (values: ChangePasswordForm) => {
		passwordMutation.mutate(values);
	};

	return (
		<Formik
			initialValues={{ oldPassword: '', newPassword: '', repeatPassword: '' }}
			validationSchema={ValidationSchema}
			onSubmit={onSubmit}
		>
			{({ values }) => (
				<Form>
					<Panel label="Cambiar Contraseña">
						<Stack spacing={2}>
							<TextInput
								name="oldPassword"
								type="password"
								label="Contraseña Anterior"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<LockOpenOutlinedIcon />
										</InputAdornment>
									),
								}}
							/>
							<Stack spacing={2} direction="row">
								<Stack spacing={2} width="100%">
									<TextInput
										name="newPassword"
										type="password"
										label="Nueva Contraseña"
									/>
									<PasswordValidator password={values.newPassword} />
								</Stack>
								<Stack spacing={2} width="100%">
									<TextInput
										name="repeatPassword"
										type="password"
										label="Repetir Contraseña"
									/>
									<PasswordMatching
										password={values.newPassword}
										repeatPassword={values.repeatPassword}
									/>
								</Stack>
							</Stack>
							<Button type="submit">Cambiar Contraseña</Button>
						</Stack>
					</Panel>
				</Form>
			)}
		</Formik>
	);
};

export default ResetPassword;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { West } from '@mui/icons-material';
import { Button, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
interface GoBackProps {
	label?: string;
	sx?: SxProps<Theme>;
	icon?: ReactNode;
	fullWidth?: boolean;
}

const GoBack = ({ label = 'Regresar', icon, fullWidth, ...rest }: GoBackProps) => {
	const navigate = useNavigate();

	return (
		<Button
			variant="outlined"
			size="large"
			startIcon={icon}
			onClick={() => navigate(-1)}
			fullWidth={fullWidth}
			{...rest}
		>
			{label}
		</Button>
	);
};

export default GoBack;

import { svgIconClasses } from '@mui/material/SvgIcon';

const buttons = {
	defaultProps: {
		variant: 'contained' as const,
		size: 'large' as const,
		disableElevation: true,
	},
	styleOverrides: {
		root: {
			borderRadius: 12,
			fontSize: '1rem',
			fontFamily: 'poppins',
			fontWeight: 700,
		},
		outlined: {
			borderWidth: 2,

			'&:hover': {
				borderWidth: 2,
			},
		},
		containedSecondary: {
			color: 'white',
			backgroundColor: 'secondary',
		},
		sizeLarge: {
			paddingLeft: 32,
			paddingRight: 32,
			height: 60,
		},
		iconSizeLarge: {
			[`.${svgIconClasses.root}`]: {
				fontSize: '2rem',
			},
		},
	},
};

export default buttons;

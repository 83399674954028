export const dialog = {
	defaultProps: {
		maxWidth: 'md' as const,
	},
	styleOverrides: {
		paper: {
			borderRadius: 24,
			fontSize: '1rem',
			gap: 24,
		},
	},
};

export const dialogTitle = {
	defaultProps: {
		maxWidth: 'md',
	},
	styleOverrides: {
		root: {
			padding: 24,
			paddingBottom: 0,
			fontFamily: 'poppins',
			fontWeight: 700,
			fontSize: 24,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: 24,
			maxWidth: 'none',
		},
	},
};

export const dialogContent = {
	styleOverrides: {
		root: {
			overflow: 'visible',
			fontFamily: 'poppins',
			gap: 16,
		},
	},
};

export const dialogActions = {
	styleOverrides: {
		root: {
			padding: 24,
			paddingTop: 0,
			display: 'flex',
		},
	},
};

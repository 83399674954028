import { Box, Typography } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { css } from '@emotion/css';
import { styles } from './styles';

interface IPassword {
	title: string;
	validations: Array<{
		label: string;
		isValid: boolean;
	}>;
}

const Validator = ({ title, validations }: IPassword) => {
	return (
		<Box className={css(styles)}>
			<Typography className="title">{title}</Typography>
			{validations.map((validation, index) => (
				<Box className="rule" key={index}>
					{validation.isValid ? (
						<CheckIcon className="icon" color="secondary" />
					) : (
						<CloseIcon className="icon" color="error" />
					)}
					<Typography className="label">{validation.label}</Typography>
				</Box>
			))}
		</Box>
	);
};

export default Validator;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

interface Option {
	name: string;
	pv: number;
}

interface ChartProps {
	data?: Option[];
}

// eslint-disable-next-line no-empty-pattern
const Chart = ({ data }: ChartProps) => {
	return (
		<Box sx={{ height: 200 }}>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
					maxBarSize={50}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="respuestas" fill="#6DB541" />
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);
};

export default Chart;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { Panel, SelectInput, TextInput } from 'components';
import { Formik, Form, FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { Survey } from 'types/surveys';
import { useMutation } from 'react-query';
import { updateCompanySurvey, deleteCompanySurvey } from 'queries/companies';
import LoadingButton from '@mui/lab/LoadingButton';

const emptyOption = {
	title: '',
	isValid: true,
};

const emptyQuestion = {
	type: 'Yes/No',
	title: '',
	minAnswers: '1',
	maxAnswers: '1',
	answerOptions: [emptyOption],
};

const CompanySurveyDetails = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { selectedCompany, setSelectedCompany, refreshCompanies } =
		useContext(AdminContext);

	const saveMutation = useMutation(updateCompanySurvey, {
		onSuccess: async res => {
			await refreshCompanies();
			navigate(-1);
		},
	});

	const deleteMutation = useMutation(deleteCompanySurvey, {
		onSuccess: async () => {
			navigate(-1);
			await refreshCompanies();
		},
	});

	const deleteSurvey = () => {
		const gotPermission = window.confirm('Desea eliminar la encuesta?');

		if (gotPermission) {
			deleteMutation.mutate({
				companyId: selectedCompany?._id ?? '',
				surveyId: state.survey._id,
			});
		}
	};

	const onSubmit = (survey: Survey) => {
		saveMutation.mutate({
			companyId: selectedCompany?._id ?? '',
			survey,
		});
	};

	if (!selectedCompany) {
		return null;
	}

	return (
		<Formik initialValues={state.survey} onSubmit={onSubmit}>
			{({ values }) => {
				return (
					<Form>
						<Stack gap={2}>
							<Stack spacing={2}>
								<TextInput name="title" label="Título" size="small" required />
								<TextInput name="description" label="Descripción" size="small" required />
								<TextInput name="image" label="Imagen" size="small" required />
								<Stack direction="row" gap={3}>
									<TextInput name="estimatedTime" label="Tiempo Estimado" size="small" />
									<TextInput name="points" label="Puntos" size="small" required />
								</Stack>
							</Stack>

							<Box
								sx={{
									padding: 2,
									backgroundColor: '#ececec',
									borderRadius: 3,
								}}
							>
								<Typography variant="h6" py={2}>
									Preguntas:
								</Typography>

								<FieldArray name="questions">
									{({ push, remove }) => (
										<Stack gap={2}>
											{((values?.questions && values?.questions) ?? [] ?? []).map(
												(_: any, questionIndex: number) => (
													<Panel
														key={questionIndex}
														label={`Pregunta ${questionIndex + 1}`}
														compact
													>
														<Grid container gap={3}>
															<Grid
																container
																sx={{
																	border: '1px solid white',
																	padding: 2,
																}}
															>
																<Grid item xs={11}>
																	<Stack spacing={2}>
																		<TextInput
																			name={`questions[${questionIndex}].title`}
																			label="Título"
																			size="small"
																		/>
																		<TextInput
																			name={`questions[${questionIndex}].description`}
																			label="Descripción"
																			size="small"
																		/>
																		<Stack direction="row" gap={3}>
																			<TextInput
																				name={`questions[${questionIndex}].minAnswers`}
																				label="Mín. de Respuestas"
																				size="small"
																				fullWidth
																			/>
																			<TextInput
																				name={`questions[${questionIndex}].maxAnswers`}
																				label="Máx. de Respuestas"
																				size="small"
																				fullWidth
																			/>
																			<SelectInput
																				name={`questions[${questionIndex}].type`}
																				label="Tipo"
																				size="small"
																				options={[
																					{
																						label: 'Single Selection',
																						value: 'Single Selection',
																					},
																					{
																						label: 'Multiple Selection',
																						value: 'Multiple Selection',
																					},
																					{
																						label: 'Yes/No',
																						value: 'Yes/No',
																					},
																				]}
																			/>
																		</Stack>
																	</Stack>
																</Grid>
																<Grid
																	item
																	xs={1}
																	sx={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'flex-start',
																	}}
																>
																	<IconButton
																		onClick={() =>
																			window.confirm('Desea eliminar la pregunta?')
																				? remove(questionIndex)
																				: null
																		}
																		color="error"
																	>
																		<DeleteIcon />
																	</IconButton>
																</Grid>
															</Grid>
															{values.questions?.[questionIndex].type ===
															'Multiple Selection' ? (
																<Grid item xs={12}>
																	<Box
																		sx={{
																			border: '1px solid silver',
																			padding: 2,
																			backgroundColor: '#ffffff',
																			borderRadius: 3,
																		}}
																	>
																		<Typography variant="subtitle1" mb={2}>
																			Opciones:
																		</Typography>
																		<FieldArray
																			name={`questions[${questionIndex}].answerOptions`}
																		>
																			{({ push: pushOption, remove }) => (
																				<Stack gap={2}>
																					{(
																						(values?.questions &&
																							values?.questions[questionIndex]
																								.answerOptions) ??
																						[]
																					).map((_: any, optionIndex: number) => (
																						<Grid container key={`option-${optionIndex}`}>
																							<Grid item xs={11}>
																								<Stack spacing={2}>
																									<TextInput
																										name={`questions[${questionIndex}].answerOptions[${optionIndex}].title`}
																										label="Título"
																										size="small"
																									/>
																								</Stack>
																							</Grid>
																							<Grid
																								item
																								xs={1}
																								sx={{
																									display: 'flex',
																									justifyContent: 'center',
																									alignItems: 'flex-start',
																								}}
																							>
																								<IconButton
																									onClick={() =>
																										window.confirm(
																											'Desea eliminar la opción?'
																										)
																											? remove(optionIndex)
																											: null
																									}
																									color="error"
																								>
																									<DeleteIcon />
																								</IconButton>
																							</Grid>
																						</Grid>
																					))}
																					<Grid container>
																						<Grid item xs={11} justifyContent="center">
																							<Button
																								size="small"
																								endIcon={<AddIcon />}
																								variant="outlined"
																								onClick={() => pushOption(emptyOption)}
																							>
																								Agregar Opción
																							</Button>
																						</Grid>
																					</Grid>
																				</Stack>
																			)}
																		</FieldArray>
																	</Box>
																</Grid>
															) : null}
														</Grid>
													</Panel>
												)
											)}
											<Button
												size="small"
												endIcon={<AddIcon />}
												variant="outlined"
												onClick={() => push(emptyQuestion)}
											>
												Agregar Pregunta
											</Button>
										</Stack>
									)}
								</FieldArray>
							</Box>
							<Stack direction="row" gap={2}>
								<LoadingButton
									loading={saveMutation.isLoading}
									size="medium"
									type="submit"
									variant="contained"
									sx={{ marginTop: 2 }}
								>
									Guardar
								</LoadingButton>
								<LoadingButton
									loading={deleteMutation.isLoading}
									size="medium"
									type="submit"
									variant="outlined"
									color="error"
									endIcon={<DeleteIcon />}
									onClick={deleteSurvey}
									sx={{ marginTop: 2 }}
								>
									Eliminar
								</LoadingButton>
							</Stack>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
};

export default CompanySurveyDetails;

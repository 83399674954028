/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserRouter, Route, Routes as AppRoutes } from 'react-router-dom';
import { AuthLayout, CompanyLayout, ProfileLayout } from 'layouts';
import {
	CreatePoints,
	RedeemPoints,
	CompanyList,
	CompanyProfile,
	CustomerProfile,
	SearchUser,
	ProfileDetails,
	Login,
	SignUp,
	EmailVerification,
	ResetPassword,
} from 'pages';
import { PrivateRoutes } from 'components';
import AdminPages from 'pages/admin';
import UserCompanyList from 'pages/admin/CompanyList';
import CompanyDetails from 'pages/admin/CompanyDetails';
import AdminCompamyRewards from 'pages/admin/CompanyRewards';
import AdminCompanySurveyList from 'pages/admin/CompanySurveyList';
import CompanySurveyLayout from 'pages/admin/CompanySurveyLayout';
import CompanySurveyDetails from 'pages/admin/CompanySurveyDetails';
import CompanySurveyAnswers from 'pages/admin/CompanySurveyAnswers';
import CompanyRewards from 'pages/company-profile/CompanyRewards';
import CompanySurveysList from 'pages/company-profile/company-surveys/CompanySurveysList';
import CompanySurveyLobby from 'pages/company-profile/company-surveys/CompanySurveyLobby';
import CompanyProvider from 'pages/company-profile/CompanyProvider';
import CompanySurvey from 'pages/company-profile/company-surveys/CompanySurvey';
import CompanySurveyCheckout from 'pages/company-profile/company-surveys/CompanySurveyCheckout';

const Routes = () => {
	return (
		<BrowserRouter>
			<AppRoutes>
				{/* PUBLIC */}
				<Route path="/" element={<CompanyLayout />}>
					<Route index element={<h2>Home</h2>} />
				</Route>
				<Route element={<AuthLayout />}>
					<Route path="/iniciar-sesion" element={<Login />} />
					<Route path="/crear-cuenta" element={<SignUp />} />
					<Route path="/confirmar-email/:emailToken" element={<EmailVerification />} />
				</Route>
				{/* PRIVATE */}
				<Route element={<PrivateRoutes />}>
					<Route element={<CompanyLayout fixed />} path="/administracion">
						<Route element={<AdminPages />}>
							<Route index element={<UserCompanyList />} />
							<Route path=":companyId" element={<CompanyDetails />} />
							<Route path=":companyId/premios" element={<AdminCompamyRewards />} />
							<Route path=":companyId/encuestas" element={<AdminCompanySurveyList />} />
							<Route
								path=":companyId/encuestas/:surveyId"
								element={<CompanySurveyLayout />}
							>
								<Route path="detalles" element={<CompanySurveyDetails />} />
								<Route path="respuestas" element={<CompanySurveyAnswers />} />
							</Route>
						</Route>
					</Route>

					<Route path="/comercios/:companyId" element={<CompanyProvider />}>
						<Route element={<CompanyLayout />}>
							<Route element={<CompanyProfile />}>
								<Route path="premios" element={<CompanyRewards />} />
								<Route path="encuestas" element={<CompanySurveysList />} />
							</Route>
						</Route>
						<Route path="encuestas/:surveyId" element={<CompanySurveyLobby />} />
						<Route path="encuestas/:surveyId/encuesta" element={<CompanySurvey />} />
						<Route
							path="encuestas/:surveyId/encuesta/listo"
							element={<CompanySurveyCheckout />}
						/>
					</Route>

					<Route element={<CompanyLayout fixed />}>
						<Route path="/user">
							<Route path="colectar-puntos" element={<RedeemPoints />} />
							<Route path="mis-puntos" element={<CompanyList />} />
						</Route>
						<Route path="/admin">
							<Route index path="crear-puntos" element={<CreatePoints />} />
							<Route path="clientes" element={<SearchUser />} />
							<Route path="clientes/:userId" element={<CustomerProfile />} />
						</Route>
						<Route path="/perfil" element={<ProfileLayout />}>
							<Route index element={<ProfileDetails />} />
							<Route path="cambiar-contrasena" element={<ResetPassword />} />
						</Route>
					</Route>
				</Route>
			</AppRoutes>
		</BrowserRouter>
	);
};

export default Routes;

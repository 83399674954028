import { Validator } from 'components';
import { RegistrationUser } from 'types/registrationUser';

const PasswordValidator = ({ password }: Pick<RegistrationUser, 'password'>) => {
	return (
		<Validator
			title="La contraseña debe tener al menos:"
			validations={[
				{
					label: '8 caracteres',
					isValid: password.trim().length >= 8,
				},
				{
					label: '1 número',
					isValid: /\d/.test(password),
				},
				{
					label: '1 mayúscula',
					isValid: /[A-Z]/.test(password),
				},
				{
					label: '1 minúscula',
					isValid: /[a-z]/.test(password),
				},
				{
					label: '1 símbolo especial',
					isValid: /[a-z]/.test(password),
				},
			]}
		/>
	);
};

export default PasswordValidator;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from 'utils/axios';
import { Reward } from 'types/reward';

export const getRewards = async ({ queryKey }: any): Promise<Reward[]> => {
	const [_key, companyId] = queryKey;
	return await request(`/companies/${companyId}/rewards`);
};

export const createReward = async (payload: Reward) => {
	return await request.post('/rewards', payload);
};

export const updateReward = async (payload: Reward) => {
	return await request.put(`/rewards/${payload._id}`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const deleteReward = async ({ rewardId }: { rewardId: Reward['_id'] }) => {
	return await request.delete(`/rewards/${rewardId}`);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Dialog from 'components/dialog/Dialog';
import {
	Card,
	CardActions,
	CardMedia,
	CardContent,
	Button,
	Typography,
	Box,
} from '@mui/material';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import theme from 'theme';
import { companyRewardsUrl } from 'constants/urls';

const { palette } = theme;

interface CompaniesProps {
	_id: string;
	name: string;
	alias?: string;
	image?: string;
	availablePoints?: number;
	onContinue?: () => void;
}

const Companies = ({ _id, name, image, availablePoints, onContinue }: CompaniesProps) => {
	const [open, setOpen] = useState(false);
	const [isHover, setIsHover] = useState(false);
	const params = useParams();
	const navigate = useNavigate();

	const readMore = () => {
		navigate(companyRewardsUrl(_id));
	};

	return (
		<>
			{params.userId && (
				<Dialog open={open} title="Confirmación" onClose={(): void => setOpen(false)}>
					<Box sx={{ textAlign: 'center' }}>
						<Typography variant="body1">Desea canjear el premio:</Typography>
						<Typography variant="h3">{name}</Typography>
					</Box>
				</Dialog>
			)}

			<Card
				className="reward"
				raised={isHover}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<Box sx={{ position: 'relative' }}>
					<CardMedia
						component="img"
						src={image ?? '/placeholder.png'}
						alt={name}
						sx={{ height: 200 }}
					/>
					<Box
						sx={{
							height: '70px',
							width: '70px',
							backgroundColor: palette.secondary.main,
							borderRadius: '56px 56px 0px 56px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 0,
							position: 'absolute',
							right: '0px',
							bottom: '0px',
						}}
					>
						<Typography variant="h3" color="white" sx={{ mb: '-6px', fontWeight: 700 }}>
							{availablePoints}
						</Typography>
						<Typography
							variant="caption"
							color="white"
							fontSize={12}
							sx={{ opacity: 0.5 }}
						>
							PTO&apos;S
						</Typography>
					</Box>
				</Box>
				<CardContent>
					<Typography variant="h3" component="div">
						{name}
					</Typography>
					<Typography variant="body1" color="text.secondary">
						Premios Disponibles
					</Typography>
				</CardContent>
				<CardActions>
					<Button
						fullWidth
						size="medium"
						variant="outlined"
						onClick={onContinue ?? readMore}
						endIcon={<ArrowRightAltIcon />}
					>
						Continuar
					</Button>
				</CardActions>
			</Card>
		</>
	);
};

export default Companies;

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { ThemeProvider } from '@mui/material/styles';
import Routes from 'Routes';
import theme from 'theme';

const queryClient = new QueryClient();

const App = () => {
	return (
		<div className="app">
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<Routes />
					</ThemeProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</Provider>
		</div>
	);
};

export default App;

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { Typography, Stack, Paper, InputAdornment, Box } from '@mui/material';
import { TextInput, Button } from 'components';
import {
	ArrowRightAlt as ArrowRightAltIcon,
	SmartphoneOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import { getUserByPhone } from 'queries/users';
import { UserPhone } from 'types/user';
import theme from 'theme';

const ValidationSchema = Yup.object().shape({
	phone: Yup.number().required('Campo Requerido'),
});

const SearchUser = () => {
	const navigate = useNavigate();
	const [phone, setPhone] = useState<UserPhone>();

	useQuery(['user-by-phone', phone], getUserByPhone, {
		enabled: !!phone,
		onSuccess: res => {
			navigate(`/admin/clientes/${res?._id}`, { state: res });
		},
	});

	const onSubmit = async (values: { phone: UserPhone }) => {
		setPhone(values.phone);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				justifyContent: 'center',
			}}
		>
			<Paper
				elevation={4}
				sx={{
					borderRadius: theme.spacing(3),
					paddingTop: theme.spacing(8),
					paddingBottom: theme.spacing(8),
					paddingLeft: theme.spacing(6),
					paddingRight: theme.spacing(6),
					width: 500,
				}}
			>
				<Stack spacing={4}>
					<Typography variant="h1">Buscar Usuario</Typography>
					<Formik
						initialValues={{ phone: '' }}
						validationSchema={ValidationSchema}
						onSubmit={onSubmit}
					>
						<Form>
							<Stack spacing={4} alignItems="center">
								<TextInput
									fullWidth
									name="phone"
									label="Número de Teléfono"
									type="number"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<SmartphoneOutlined />
											</InputAdornment>
										),
									}}
								/>
								<Button
									sx={{ justifyContent: 'space-between' }}
									fullWidth
									type="submit"
									endIcon={<ArrowRightAltIcon />}
								>
									Buscar
								</Button>
							</Stack>
						</Form>
					</Formik>
				</Stack>
			</Paper>
		</Box>
	);
};

export default SearchUser;

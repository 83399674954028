/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { WestOutlined } from '@mui/icons-material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type TabOptions = 'detalles' | 'respuestas';

const CompanySurveyLayout = () => {
	const { state, pathname } = useLocation();
	const navigate = useNavigate();
	const { selectedCompany } = useContext(AdminContext);

	const handleChange = (event: React.SyntheticEvent, selectedTab: TabOptions) => {
		const path = pathname ?? '';
		const newPath = path.split('/');
		newPath.pop();
		newPath.push(selectedTab);
		navigate(`${newPath.join('/')}`, { state });
	};

	if (!selectedCompany) {
		return null;
	}

	return (
		<>
			<Stack direction="row" gap={3} alignItems="center" sx={{ paddingBottom: 3 }}>
				<IconButton onClick={() => navigate(-1)}>
					<WestOutlined />
				</IconButton>
				<Typography variant="h2">Encuesta: {state.survey?.title}</Typography>
			</Stack>
			<Tabs onChange={handleChange} value={pathname.split('/').pop()}>
				<Tab value="detalles" label="Detalles" />
				<Tab value="respuestas" label="Respuestas" />
			</Tabs>
			<Paper sx={{ padding: 3 }}>
				<Outlet />
			</Paper>
		</>
	);
};

export default CompanySurveyLayout;

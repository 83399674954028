/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
	Typography,
	Stack,
	Button as MuiButton,
	Alert,
	InputAdornment,
} from '@mui/material';
import { Button, TextInput } from 'components';
import { Navigate, Link, useLocation } from 'react-router-dom';
import {
	EmailOutlined as EmailOutlinedIcon,
	LockOpenOutlined as LockOpenOutlinedIcon,
} from '@mui/icons-material';
import { useMutation } from 'react-query';
import { signin } from 'queries/auth';
import { useAuth } from 'hooks';
import { SignInResponse } from 'types/api';

const ValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Correo no valido')
		.required('Requerido')
		.required('Campo requerido'),
	password: Yup.string().min(2, 'Muy corto').max(30, 'Muy Largo').required('Requerido'),
});

const Login = () => {
	const { state } = useLocation();
	const { signIn, isLoggedIn } = useAuth();

	const signinMutation = useMutation(signin, {
		onSuccess: (res: SignInResponse) => {
			signIn(res);
		},
	});

	if (isLoggedIn) {
		return <Navigate to={state?.prevPage ?? '/'} replace />;
	}

	const onSubmit = ({ email, password }: any) => {
		signinMutation.mutate({ email, password });
	};

	// TODO: Fix error message
	return (
		<>
			{signinMutation.isError && <Alert severity="error">...</Alert>}
			<Formik
				initialValues={{ email: '', password: '' }}
				validationSchema={ValidationSchema}
				onSubmit={onSubmit}
			>
				{() => (
					<Form>
						<Stack spacing={2}>
							<Typography variant="h2" component="h1">
								Iniciar Sesión
							</Typography>
							{signinMutation.isError && <Alert severity="error">{'error'}...</Alert>}
							<TextInput
								name="email"
								type="email"
								label="Correo"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<EmailOutlinedIcon />
										</InputAdornment>
									),
								}}
							/>
							<TextInput
								name="password"
								type="password"
								label="Contraseña"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<LockOpenOutlinedIcon />
										</InputAdornment>
									),
								}}
							/>
							<Button isLoading={signinMutation.isLoading} type="submit">
								Iniciar Sesión
							</Button>
							<Button variant="outlined" color="primary">
								Recuperar Contraseña
							</Button>
						</Stack>
					</Form>
				)}
			</Formik>
			<MuiButton
				component={Link}
				to="/crear-cuenta"
				type="text"
				variant="text"
				color="secondary"
			>
				Crear Cuenta
			</MuiButton>
		</>
	);
};

export default Login;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { useMutation } from 'react-query';
import { addCompanySurvey } from 'queries/companies';
import { Company } from 'types/company';
import { Survey } from 'types/surveys';

const emptySurvey = {
	title: '',
	points: 1,
	estimatedTime: 1,
};

const CompanySurveyList = () => {
	const navigate = useNavigate();
	const { selectedCompany, setSelectedCompany, refreshCompanies } =
		useContext(AdminContext);

	const surveyMutation = useMutation(addCompanySurvey, {
		onSuccess: async (res: Company) => {
			setSelectedCompany(res);
			await refreshCompanies();

			// Navigate to the surveys details page
			const survey: Survey | null = res.surveys
				? res.surveys[res.surveys.length - 1]
				: null;

			if (survey) {
				navigate(survey._id, {
					state: { survey },
				});
			}
		},
	});

	const addSurvey = () => {
		surveyMutation.mutate({
			companyId: selectedCompany?._id ?? '',
			survey: emptySurvey,
		});
	};

	if (!selectedCompany) {
		return null;
	}

	return (
		<>
			<Typography variant="h2" sx={{ marginBottom: 2 }}>
				Seleccione una encuesta:
			</Typography>
			<Stack gap={2}>
				{selectedCompany.surveys?.map(s => (
					<Card className="reward" key={s._id}>
						<CardContent>
							<Typography variant="h3">{s.title}</Typography>
						</CardContent>
						<CardActions>
							<Button
								size="medium"
								variant="outlined"
								onClick={() => {
									navigate(`${s._id}/detalles`, {
										state: {
											survey: s,
										},
									});
								}}
								endIcon={<ArrowRightAltIcon />}
							>
								Continuar
							</Button>
						</CardActions>
					</Card>
				))}
			</Stack>
			<LoadingButton
				loading={surveyMutation.isLoading}
				size="medium"
				type="submit"
				variant="contained"
				endIcon={<AddIcon />}
				sx={{ marginTop: 2 }}
				onClick={addSurvey}
			>
				crear
			</LoadingButton>
		</>
	);
};

export default CompanySurveyList;

import { createContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getUserPoints, getCompanyById } from 'queries';
import useAuth from 'hooks/useAuth';
import { Company } from 'types/company';
import { Survey } from 'types/surveys';

interface CompanyContextValues {
	company?: Company;
	points?: number;
	isLoading: boolean;
	activeSurvey: Survey | null;
}

export const CompanyContext = createContext<CompanyContextValues>({
	company: undefined,
	points: undefined,
	isLoading: false,
	activeSurvey: null,
});

const CompanyProvider = () => {
	const { companyId, surveyId } = useParams();
	const { user } = useAuth();

	const companyQuery = useQuery(['company', companyId], getCompanyById);
	const pointsQuery = useQuery(['points', companyId, user?._id], getUserPoints, {
		enabled: !!user?._id,
	});

	const activeSurvey = useMemo(() => {
		if (!surveyId || !companyQuery.data) return null;

		return companyQuery.data?.surveys?.find(s => s._id === surveyId) ?? null;
	}, [surveyId, companyQuery.data]);

	return (
		<CompanyContext.Provider
			value={{
				company: companyQuery.data,
				points: pointsQuery.data?.points,
				isLoading: companyQuery.isLoading ?? pointsQuery.isLoading,
				activeSurvey,
			}}
		>
			<Outlet />
		</CompanyContext.Provider>
	);
};

export default CompanyProvider;

import { Dispatch, SetStateAction } from 'react';
import { AnswerOption, Answers } from '../../../../types/surveys';
import { ButtonBase, Stack } from '@mui/material';
import theme from 'theme';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface MultipleSelectionProps {
	answers: string[];
	setAnswers: Dispatch<SetStateAction<Answers>>;
	options: AnswerOption[];
	maxAnswers: number;
	questionId: string;
}

const { palette } = theme;
const MultipleSelection = ({
	answers = [],
	setAnswers,
	options,
	maxAnswers,
	questionId,
}: MultipleSelectionProps) => {
	return (
		<Stack gap={3}>
			{options.map(o => (
				<ButtonBase
					key={o._id}
					sx={{
						border: 1,
						borderRadius: 2,
						borderColor: palette.primary.main,
						backgroundColor: answers.includes(o._id) ? palette.primary.light : null,
						display: 'flex',
						flexGrow: 1,
						height: 60,
						// Typography
						fontSize: 16,
						justifyContent: 'left',
						color: answers.includes(o._id) ? palette.primary.main : 'grey',
						p: 3,
						gap: 2,
					}}
					onClick={() => {
						setAnswers(state => {
							const tempState = { ...state };
							const currAnswers = [...answers];
							const optionIndex = currAnswers.findIndex(id => id === o._id);

							if (optionIndex >= 0) {
								// Uncheck selected option if was already checked
								currAnswers.splice(optionIndex, 1);
							} else if (currAnswers.length < maxAnswers) {
								// Check new option
								currAnswers.push(o._id);
							}

							tempState[questionId] = currAnswers;
							return tempState;
						});
					}}
				>
					{answers.includes(o._id) ? (
						<CheckBox color="primary" />
					) : (
						<CheckBoxOutlineBlank />
					)}
					{o.title}
				</ButtonBase>
			))}
		</Stack>
	);
};

export default MultipleSelection;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { Panel, TextInput } from 'components';
import { Formik, Form, FieldArray } from 'formik';
import { Company } from 'types/company';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { useMutation } from 'react-query';
import { updateCompanyRewards } from 'queries';
import LoadingButton from '@mui/lab/LoadingButton';

const emptyReward = {
	name: '',
	description: '',
	points: 0,
	image: '',
};

const CompanyRewards = () => {
	const { selectedCompany, refreshCompanies } = useContext(AdminContext);

	const companyMutation = useMutation(updateCompanyRewards, {
		onSuccess: async () => {
			await refreshCompanies();
		},
	});

	const onSubmit = (values: Company) => {
		companyMutation.mutate({ id: values._id, payload: values?.rewards ?? [] });
	};

	if (!selectedCompany) {
		return null;
	}

	return (
		<Stack gap={2}>
			<Typography variant="h2">Premios</Typography>
			<Formik initialValues={selectedCompany} onSubmit={onSubmit}>
				{({ values }) => {
					return (
						<Form>
							<FieldArray name="rewards">
								{({ push, remove }) => (
									<Stack gap={2}>
										{(values.rewards ?? []).map((_: any, rewardIndex: number) => (
											<Box key={rewardIndex}>
												<Panel label={`Premio ${rewardIndex + 1}`} compact>
													<Grid container>
														<Grid item xs={11}>
															<Stack spacing={2} sx={{ flex: 1 }}>
																<Grid container spacing={3}>
																	<Grid item xs={9}>
																		<TextInput
																			name={`rewards[${rewardIndex}].title`}
																			label="Título"
																			size="small"
																			fullWidth
																		/>
																	</Grid>
																	<Grid item xs={3}>
																		<TextInput
																			name={`rewards[${rewardIndex}].points`}
																			label="Puntos"
																			size="small"
																			fullWidth
																		/>
																	</Grid>
																</Grid>
																<TextInput
																	name={`rewards[${rewardIndex}].description`}
																	label="Descripción"
																	size="small"
																/>
																<TextInput
																	name={`rewards[${rewardIndex}].image`}
																	label="Imagen"
																	size="small"
																/>
															</Stack>
														</Grid>
														<Grid item xs={1}>
															<IconButton
																onClick={() => remove(rewardIndex)}
																color="error"
															>
																<DeleteIcon />
															</IconButton>
														</Grid>
													</Grid>
												</Panel>
											</Box>
										))}
										<Button
											size="small"
											endIcon={<AddIcon />}
											variant="outlined"
											onClick={() => push(emptyReward)}
										>
											Agregar Pregunta
										</Button>
									</Stack>
								)}
							</FieldArray>
							<LoadingButton
								loading={companyMutation.isLoading}
								size="medium"
								type="submit"
								variant="contained"
								sx={{ marginTop: 2 }}
							>
								Guardar
							</LoadingButton>
						</Form>
					);
				}}
			</Formik>
		</Stack>
	);
};

export default CompanyRewards;

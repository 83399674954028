import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { Panel, TextInput } from 'components';
import { Formik, Form } from 'formik';
import { Company } from 'types/company';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompanyDetails, CompanyDetailsPayload } from 'queries/companies';
import LoadingButton from '@mui/lab/LoadingButton';

const CompanyDetails = () => {
	const queryClient = useQueryClient();
	const { selectedCompany } = useContext(AdminContext);

	const companyMutation = useMutation(updateCompanyDetails, {
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['companies'] });
		},
	});

	const onSubmit = (v: Company) => {
		const payload: CompanyDetailsPayload = {
			name: v.name ?? '',
			alias: v.alias ?? '',
			pricePerPoint: v.pricePerPoint ?? 0,
			logo: v.logo ?? '',
			image: v.image ?? '',
			phone: v.phone ?? '',
			website: v.website ?? '',
		};

		companyMutation.mutate({ id: v._id, payload });
	};

	if (!selectedCompany) {
		return null;
	}

	return (
		<Stack gap={2}>
			<Typography variant="h2">Detalles</Typography>
			<Formik initialValues={selectedCompany} onSubmit={onSubmit}>
				{({ values }) => {
					return (
						<Form>
							<Stack gap={3}>
								<Panel compact label="Datos generales">
									<Stack spacing={2}>
										<Stack direction="row" gap={3}>
											<TextInput fullWidth name="name" label="Nombre" size="small" />
											<TextInput fullWidth name="alias" label="Alias" size="small" />
										</Stack>
										<TextInput
											name="pricePerPoint"
											label="Precio por punto"
											size="small"
										/>
									</Stack>
								</Panel>
								<Panel compact label="Datos generales">
									<Stack direction="row" gap={3}>
										<TextInput fullWidth name="phone" label="Teléfono" size="small" />
										<TextInput fullWidth name="website" label="Sitio Web" size="small" />
									</Stack>
								</Panel>
								<Panel compact label="Multimedia">
									<Stack direction="row" gap={3}>
										<TextInput name="image" label="Portada" size="small" />
										<TextInput name="logo" label="Logo" size="small" />
									</Stack>
								</Panel>
							</Stack>
							<LoadingButton
								loading={companyMutation.isLoading}
								size="medium"
								type="submit"
								variant="contained"
								sx={{ marginTop: 2 }}
							>
								Guardar
							</LoadingButton>
						</Form>
					);
				}}
			</Formik>
		</Stack>
	);
};

export default CompanyDetails;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Check,
	Close,
	RadioButtonChecked,
	RadioButtonUnchecked,
} from '@mui/icons-material';
import { ButtonBase, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { AnswerOption, Answers } from 'types/surveys';
import theme from '../../../../theme';

interface OneSelectionProps {
	answers: string[];
	setAnswers: Dispatch<SetStateAction<Answers>>;
	options: AnswerOption[];
	questionId: string;
}

const SingleSelection = ({
	answers = [],
	setAnswers,
	options,
	questionId,
}: OneSelectionProps) => {
	const { palette } = theme;
	const selectedOption = answers[0] ?? '';

	return (
		<Stack gap={3}>
			{options.map(o => {
				const isSelected = selectedOption === o._id;
				return (
					<ButtonBase
						key={o._id}
						sx={{
							backgroundColor: isSelected ? palette.primary.light : 'none',
							display: 'flex',
							flexGrow: 1,
							height: 60,
							width: '100% !important',
							minWidth: '100% !important',
							border: 2,
							borderColor: isSelected ? palette.primary.main : 'grey',
							borderRadius: 2,
							// Typography
							color: isSelected ? `${palette.primary.main}` : 'grey',
							fontSize: 16,
							justifyContent: 'left',
							p: 3,
							gap: 2,
						}}
						onClick={() => {
							setAnswers(answers => {
								const tempAnswers = { ...answers };
								tempAnswers[questionId] = [o._id];
								return tempAnswers;
							});
						}}
					>
						{isSelected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
						{o.title}
					</ButtonBase>
				);
			})}
		</Stack>
	);
};

export default SingleSelection;

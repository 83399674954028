import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { Alert, Box, LinearProgress, Snackbar } from '@mui/material';
import { hideSnackbar, useDispatch, useSelector } from 'store';

const PrivateRoutes = () => {
	const { isLoggedIn } = useAuth();
	const dispatch = useDispatch();
	const { snackbar, showProgressBar } = useSelector(state => state.ui);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	if (!isLoggedIn) {
		navigate('/iniciar-sesion', { state: { prevPage: pathname ?? '/' } });
	}

	return (
		<Box>
			{showProgressBar ? <LinearProgress /> : null}
			<Outlet />
			<Snackbar
				open={!(snackbar == null)}
				autoHideDuration={6000}
				onClose={() => dispatch(hideSnackbar())}
			>
				<Alert variant="filled" severity={snackbar?.severity} sx={{ width: '100%' }}>
					{snackbar?.message}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default PrivateRoutes;

import { useContext, useMemo, useState } from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Dialog, Reward } from 'components';
import { Reward as IReward } from 'types/reward';
import { CompanyContext } from './CompanyProvider';

const CompanyRewards = () => {
	const theme = useTheme();
	const { company, points } = useContext(CompanyContext);
	const [openRedeemDialog, setOpenRedeemDialog] = useState(false);

	const availableRewards = useMemo(
		() =>
			(company?.rewards ?? []).filter(
				(reward: IReward) => reward.points <= (points ?? 0)
			),
		[company?.rewards, points]
	);

	const unavailableRewards = useMemo(
		() =>
			(company?.rewards ?? []).filter((reward: IReward) => reward.points > (points ?? 0)),
		[company?.rewards, points]
	);

	return (
		<>
			<Paper sx={{ borderRadius: 6, padding: 3 }}>
				{availableRewards.length ? (
					<>
						<Stack direction={'row'} gap={2} py={2} alignItems={'center'}>
							<CheckCircle
								fontSize="large"
								sx={{ color: theme.palette.secondary.main }}
							/>
							<Typography variant="h3" fontWeight={600}>
								Listos para canjear
							</Typography>
						</Stack>
						<Stack spacing={4}>
							{availableRewards.map((reward: IReward, index: number) => (
								<Reward
									key={`reward-${index}`}
									reward={reward}
									availablePoints={points}
									actions={[
										{
											children: 'Canjear',
											variant: 'contained',
											color: 'primary',
											onClick: () => setOpenRedeemDialog(true),
										},
									]}
								/>
							))}
						</Stack>
					</>
				) : null}

				{unavailableRewards.length ? (
					<>
						<Stack direction={'row'} gap={2} py={2} alignItems={'center'}>
							<Cancel fontSize="large" sx={{ color: 'red' }} />
							<Typography variant="h3" fontWeight={600}>
								Continúa acumulando
							</Typography>
						</Stack>
						<Stack spacing={4}>
							{unavailableRewards.map((reward: IReward, index: number) => (
								<Reward
									key={`reward-${index}`}
									reward={reward}
									availablePoints={points}
									actions={[
										{
											children: 'Canjear',
											disabled: true,
										},
									]}
								/>
							))}
						</Stack>
					</>
				) : null}
			</Paper>
			<Dialog
				maxWidth="xs"
				open={openRedeemDialog}
				onClose={() => setOpenRedeemDialog(false)}
				actions={[
					{
						children: 'Cancelar',
						onClick: () => setOpenRedeemDialog(false),
						variant: 'outlined',
						fullWidth: true,
					},
				]}
			>
				<Typography variant="h3" sx={{ textAlign: 'center' }}>
					Pasos para Canjear:
				</Typography>
				<ol>
					<li>Visita la sucursal de tu preferencia</li>
					<li>Presenta tu cédula de identidad</li>
					<li>Solicita canjear tu premio</li>
				</ol>
			</Dialog>
		</>
	);
};

export default CompanyRewards;

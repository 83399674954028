import Box, { BoxProps } from '@mui/material/Box';
import theme from 'theme';

interface ILogo {
	size?: number | string;
	color?: 'white' | 'orange';
	sx?: BoxProps['sx'];
}

const Logo = ({ size = '2rem', color = 'orange', sx = {} }: ILogo) => {
	return (
		<Box
			sx={{
				fontSize: size,
				fontFamily: 'Mitr',
				fontWeight: 700,
				color: color === 'orange' ? theme.palette.primary.main : 'white',
				...sx,
			}}
		>
			Rebit
		</Box>
	);
};

export default Logo;

import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as userQueries from 'queries/users';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Stack } from '@mui/material';
import { Button, TextInput, Panel, Detail } from 'components';
import { User } from 'types/user';
import { useDispatch } from 'store/hooks';
import * as authSlice from 'store/authSlice';
import useAuth from 'hooks/useAuth';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Muy Corto').max(30, 'Muy Largo').required('Campo requerido'),
	lastName: Yup.string()
		.min(2, 'Muy Corto')
		.max(30, 'Muy Largo')
		.required('Campo requerido'),
	phone: Yup.string()
		.min(7, 'Muy Corto')
		.max(12, 'Muy Largo')
		.required('Campo requerido'),
	email: Yup.string().email('Correo no valido').required('Requerido'),
});

const ProfileDetails = () => {
	const [editMode, setEditMode] = useState<Boolean>();
	const dispatch = useDispatch();
	const { user } = useAuth();
	const queryClient = useQueryClient();
	const userQuery = useQuery(['user', user?._id], userQueries.getUserById, {
		enabled: !!user,
	});

	const userMutation = useMutation(userQueries.updateUser, {
		onSuccess: async res => {
			await queryClient.invalidateQueries('user');
		},
	});

	// TODO: Replace this with the response updated once the issue is fixed in the BE
	useEffect(() => {
		if (userQuery.data) {
			dispatch(authSlice.updateUser(userQuery.data));
		}
	}, [userQuery.data]);

	const onSubmit = (values: User) => {
		userMutation.mutate({ ...userQuery.data, ...values });
	};

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: '16px' }}>
				<Button onClick={() => setEditMode(!editMode)} variant="outlined">
					{editMode ? 'Cancelar' : 'Editar'}
				</Button>
			</Box>
			<Stack spacing={2}>
				<Formik
					enableReinitialize
					initialValues={{
						name: userQuery.data?.name ?? '',
						lastName: userQuery.data?.lastName ?? '',
						idInfo: userQuery.data?.idInfo ?? '',
						birthDate: userQuery.data?.birthDate ?? '',
						phone: userQuery.data?.phone ?? '',
						email: userQuery.data?.email ?? '',
						role: userQuery.data?.role ?? [],
					}}
					validationSchema={ValidationSchema}
					onSubmit={onSubmit}
				>
					{({ errors, touched }: { errors: any; touched: any }) =>
						editMode ? (
							<Form>
								<Stack spacing={3}>
									<Panel label="Detalles">
										<Stack spacing={2}>
											<Stack spacing={2} direction="row">
												<TextInput fullWidth name="name" label="Nombre" />
												<TextInput fullWidth name="lastName" label="Apellidos" />
											</Stack>
											<TextInput name="id" label="Cedula" />
											<TextInput
												name="birthDate"
												type="date"
												label="Fecha de Nacimiento"
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Stack>
									</Panel>
									<Panel label="Datos de Contacto">
										<Stack spacing={2} direction="row">
											<TextInput fullWidth name="phone" label="Celular" />
											<TextInput fullWidth name="email" type="email" label="Correo" />
										</Stack>
									</Panel>
									<Button sx={{ width: '850px' }} type="submit">
										Guardar
									</Button>
								</Stack>
							</Form>
						) : (
							<Stack spacing={2}>
								<Panel label="Detalles">
									<Stack spacing={2} direction="row">
										<Detail label={'Nombre'} content={userQuery.data?.name ?? ''} />
										<Detail label={'Apellido'} content={userQuery.data?.lastName ?? ''} />
									</Stack>
									{/* <Detail label={'Cedula'} content={userQuery.data?.idInfo ?? ''} /> */}
									<Detail
										label={'Fecha de Nacimiento'}
										content={userQuery.data?.birthDate ?? ''}
									/>
								</Panel>
								<Panel label="Datos de Contacto">
									<Stack spacing={2} direction="row">
										<Detail label={'Celular'} content={userQuery.data?.phone ?? ''} />
										<Detail label={'Email'} content={userQuery.data?.email ?? ''} />
									</Stack>
								</Panel>
							</Stack>
						)
					}
				</Formik>
			</Stack>
		</>
	);
};

export default ProfileDetails;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useEffect, useState } from 'react';
import { Link, useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth } from 'hooks';
import { getUserCompanies } from 'queries';
import {
	IconButton,
	Container,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Stack,
	Typography,
} from '@mui/material';
import { Store, HowToVote, Redeem, WestOutlined } from '@mui/icons-material';
import { Company } from 'types/company';

interface AdminContextValues<T> {
	selectedCompany?: Company;
	setSelectedCompany: any;
	companies?: Company[];
	refreshCompanies: () => Promise<void>;
}

export const AdminContext = createContext<AdminContextValues<{}>>({
	selectedCompany: undefined,
	setSelectedCompany: () => {},
	companies: undefined,
	refreshCompanies: async () => {},
});

const AdminPages = () => {
	const { companyId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedCompany, setSelectedCompany] = useState<Company>();

	const { user } = useAuth();
	const companiesQuery = useQuery(['user-companies', user?.companies], getUserCompanies);

	useEffect(() => {
		if (companiesQuery.data && selectedCompany) {
			const selected = companiesQuery.data.find(c => c._id === selectedCompany._id);
			setSelectedCompany(selected);
		}
	}, [companiesQuery.data]);

	const refreshCompanies = async () => {
		await companiesQuery.refetch();
	};

	const resetCompany = () => {
		navigate(`/administracion`);
		setSelectedCompany(undefined);
	};

	return (
		<AdminContext.Provider
			value={{
				companies: companiesQuery.data,
				selectedCompany,
				setSelectedCompany,
				refreshCompanies,
			}}
		>
			<Stack gap={2} direction="column" sx={{ width: '100%' }}>
				<Stack direction="row" gap={3} alignItems="center" sx={{ paddingBottom: 3 }}>
					{location.pathname !== '/administracion' ? (
						<IconButton onClick={resetCompany}>
							<WestOutlined />
						</IconButton>
					) : null}
					<Typography variant="h1">
						{location.pathname !== '/administracion'
							? selectedCompany?.name
							: 'Select a Company'}
					</Typography>
				</Stack>
				<Divider />
				<Container>
					<Grid container>
						{location.pathname !== '/administracion' ? (
							<Grid item md={3}>
								<MenuList>
									<MenuItem component={Link} to={`/administracion/${companyId}`}>
										<ListItemIcon>
											<Store fontSize="small" />
										</ListItemIcon>
										<ListItemText>Detalles</ListItemText>
									</MenuItem>
									<MenuItem component={Link} to={`/administracion/${companyId}/premios`}>
										<ListItemIcon>
											<Redeem fontSize="small" />
										</ListItemIcon>
										<ListItemText>Premios</ListItemText>
									</MenuItem>
									<MenuItem
										component={Link}
										to={`/administracion/${companyId}/encuestas`}
									>
										<ListItemIcon>
											<HowToVote fontSize="small" />
										</ListItemIcon>
										<ListItemText>Encuestas</ListItemText>
									</MenuItem>
								</MenuList>
							</Grid>
						) : null}
						<Grid item md={9}>
							<Outlet />
						</Grid>
					</Grid>
				</Container>
			</Stack>
		</AdminContext.Provider>
	);
};

export default AdminPages;

export const listItem = {
	styleOverrides: {
		root: {
			// border: '1px solid red !important',
		},
	},
};

export const listItemText = {
	styleOverrides: {
		root: {
			// border: '1px solid blue',
			fontFamily: 'poppins',
			fontSize: '1rem',
			fontWeight: '500',
		},
	},
};

export const listItemIcon = {
	styleOverrides: {
		root: {
			// border: '5px solid green',
		},
	},
};

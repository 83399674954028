import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Chart from './Chart';

const CompanySurveyAnswers = () => {
	const { state } = useLocation();

	const totalAnswers = useMemo(() => {
		const totalAnswers: {
			[key: string]: {
				[key: string]: number;
			};
		} = {};

		if (state?.survey?.answers) {
			state?.survey?.answers.forEach((a: any) => {
				(a?.questions ?? []).forEach((q: any) => {
					// totalAnswers[q?.questionId] = {};

					(q?.selectedOptions ?? []).forEach((o: any) => {
						if (!totalAnswers[q?.questionId]) {
							totalAnswers[q?.questionId] = {};
						}

						if (!totalAnswers[q?.questionId][o]) {
							totalAnswers[q?.questionId][o] = 0;
						}

						totalAnswers[q?.questionId][o] = totalAnswers[q?.questionId][o] + 1;
					});
				});
			});
		}

		return totalAnswers;
	}, [state?.survey?.answers]);

	const answers = useMemo(() => {
		if (state?.survey) {
			return state?.survey.questions.map((q: any, i: number) => {
				return {
					question: {
						title: q.title,
						options: q.answerOptions.length
							? q.answerOptions.map((o: any) => ({
									...o,
									totalAnswers: totalAnswers[q._id]?.[o._id]
										? totalAnswers[q._id][o._id]
										: 0,
							  }))
							: [
									{
										title: 'Yes',
										_id: `option-${i}-yes`,
										totalAnswers: totalAnswers[q._id]?.yes,
									},
									{
										title: 'No',
										_id: `option-${i}-no`,
										totalAnswers: totalAnswers[q._id]?.no,
									},
							  ],
					},
				};
			});
		}

		return [];
	}, [state?.survey, totalAnswers]);

	return (
		<>
			{answers.map((a: any, index: number) => (
				<Box key={`answer-${index}`} mb={6}>
					<Typography variant="h3" mb={2}>
						{a.question.title}
					</Typography>
					<Chart
						data={a.question.options.map((o: any) => ({
							name: o.title,
							respuestas: o.totalAnswers ?? 0,
						}))}
					/>
				</Box>
			))}
		</>
	);
};

export default CompanySurveyAnswers;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper, Stack } from '@mui/material';
import { CompanyContext } from '../CompanyProvider';
import { useContext } from 'react';
import { SurveyCard } from 'components';

const CompanySurveys = () => {
	const { company, points } = useContext(CompanyContext);

	return (
		<Paper sx={{ borderRadius: 6, padding: 3 }}>
			<h1>Surveys</h1>
			<Stack gap={4}>
				{company?.surveys?.map(survey => (
					<SurveyCard key={survey._id} {...survey} />
				))}
			</Stack>
		</Paper>
	);
};

export default CompanySurveys;

import { Field } from 'formik';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

interface TextInputProps {
	name: string;
}

const TextInput = ({ name, ...rest }: TextInputProps & TextFieldProps) => {
	return (
		<Field name={name}>
			{({ field, meta }: { field: any; form: any; meta: any }) => {
				const { error, touched } = meta;
				return (
					<TextField
						fullWidth={false}
						{...field}
						{...rest}
						error={touched && !!error}
						helpertext={touched ? error : ''}
					/>
				);
			}}
		</Field>
	);
};

export default TextInput;

export const getInitials = (fullName: string) => {
	if (fullName.trim().length > 0) {
		return fullName
			.split(' ')
			.map(word => word.charAt(0))
			.join('')
			.slice(0, 2)
			.toUpperCase();
	}
	return '';
};

import { grey } from '@mui/material/colors';

export const inputBase = {
	styleOverrides: {
		root: {
			height: 60, // TODO: Move later to large inputs only
			fontSize: '1rem',
			backgroundColor: grey[100],
			borderRadius: '0.5rem !important',
			fontFamily: 'Poppins',
			fontWeight: 400,

			'& fieldset': {
				color: grey[300],
				borderRadius: '0.5rem',
			},
		},
		sizeSmall: {
			borderColor: 'red',
			height: 40,
			fontSize: '0.75rem',
			borderRadius: '0.25rem !important',

			'& fieldset': {
				borderRadius: '0.25rem',
			},
		},
	},
};

export const outlinedInput = {
	styleOverrides: {
		root: {
			'& fieldset': {
				borderWidth: 2,
				borderColor: grey[300],
			},
		},
		sizeSmall: {
			'& fieldset': {
				borderWidth: 1,
			},
		},
	},
};

export const inputLabel = {
	styleOverrides: {
		root: {
			fontWeight: 400,
			fontFamily: 'Poppins',
			fontSize: '1rem',
		},
	},
};

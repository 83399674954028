import { Validator } from 'components';
import { RegistrationUser } from 'types/registrationUser';

const PasswordMatching = ({
	password,
	repeatPassword,
}: Pick<RegistrationUser, 'password' | 'repeatPassword'>) => {
	return (
		<Validator
			title="Las contraseñas deben ser iguales:"
			validations={[
				{
					label: 'Ser iguales',
					isValid: password === repeatPassword,
				},
			]}
		/>
	);
};
export default PasswordMatching;

import { Typography, Paper } from '@mui/material';
import { ReactNode } from 'react';
import theme from 'theme';

const { spacing, palette } = theme;

interface PanelProps {
	label?: string;
	children: ReactNode;
	compact?: boolean;
}

const Panel = ({ label, children, compact = false }: PanelProps) => {
	return (
		<>
			<Paper
				elevation={1}
				sx={{
					borderRadius: spacing(1),
					padding: spacing(compact ? 2 : 3),
					paddingTop: spacing(label ? (compact ? 1 : 2) : compact ? 2 : 3),
				}}
			>
				{label ? (
					<Typography
						sx={{
							fontSize: '12px',
							fontWeight: '700',
							textTransform: 'uppercase',
							paddingBottom: spacing(2),
							color: palette.secondary.main,
						}}
					>
						{label}
					</Typography>
				) : null}
				{children}
			</Paper>
		</>
	);
};

export default Panel;

import { useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { useContext } from 'react';
import { AdminContext } from './index';
import { Company } from 'types/company';
import { Masonry } from '@mui/lab';

const CompanyList = () => {
	const navigate = useNavigate();
	const { companies, setSelectedCompany } = useContext(AdminContext);

	const selectCompany = (company: Company) => {
		setSelectedCompany(company);
		navigate(`/administracion/${company._id}`);
	};

	if (!companies) {
		return <h1>Loading...</h1>;
	}

	return (
		<Stack gap={2}>
			<Masonry columns={{ xs: 1, sm: 2 }} spacing={3}>
				{companies.map(company => (
					<Card
						className="reward"
						key={company._id}
						sx={{ justifyContent: 'center', alignItems: 'center', padding: 2 }}
					>
						<CardContent>
							<Typography variant="h2" component="h3">
								{company.name}
							</Typography>
						</CardContent>
						<CardActions sx={{ width: '100%' }}>
							<Button
								fullWidth
								size="medium"
								variant="outlined"
								onClick={() => selectCompany(company)}
								endIcon={<ArrowRightAltIcon />}
							>
								Continuar
							</Button>
						</CardActions>
					</Card>
				))}
			</Masonry>
		</Stack>
	);
};

export default CompanyList;

export const palette = {
	primary: {
		light: '#FDD1B0',
		main: '#EB6E1E',
		50: '#FDEEE4',
		100: '#F9D4BC',
		200: '#F5B78F',
		300: '#F9D4BC',
		400: '#EE8440',
		500: '#EB6E1E',
		600: '#EB6E1E',
		700: '#E55B16',
		800: '#E25112',
		900: '#DD3F0A',
	},
	secondary: {
		light: '#CEE5B7',
		main: '#6DB541',
		50: '#EDF6E8',
		100: '#D3E9C6',
		200: '#B6DAA0',
		300: '#99CB7A',
		400: '#83C05E',
		500: '#6DB541',
		600: '#65AE3B',
		700: '#5AA532',
		800: '#509D2A',
		900: '#3E8D1C',
	},
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from 'utils/axios';
import { Answers, Survey, SurveyApiResponse } from 'types/surveys';

export const getSurveyById = async ({ queryKey }: any): Promise<SurveyApiResponse[]> => {
	const [_key, companyId] = queryKey;
	return await request(`/surveys/${companyId}`);
};

export const updateSurvey = async (payload: SurveyApiResponse) => {
	const body: Partial<Survey> = { ...payload };

	delete body._id;
	delete body.answers;

	return await request.put(`/surveys/${payload._id}`, body);
};

// Respod Survey

interface RespondSurveyParams {
	companyId: string;
	surveyId: string;
	answers: Answers;
}

export const respondSurvey = async ({
	companyId,
	surveyId,
	answers,
}: RespondSurveyParams): Promise<any> => {
	return await request.post(`/companies/${companyId}/surveys/${surveyId}`, answers);
};

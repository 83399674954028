import { ButtonBase, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import pluralize from 'pluralize';
import theme from 'theme';

const MAX_POINTS = 10;
const STATUS = {
	available: theme.palette.secondary.main,
	required: theme.palette.error.main,
	notNecessary: theme.palette.grey[300],
};

interface IPoints {
	requiredPoints: number;
	availablePoints: number;
}

interface IPointsGroup {
	status: string;
}

const Points = ({ requiredPoints, availablePoints }: IPoints) => {
	const points = useMemo(() => {
		const points = [];

		for (let i = 0; i < MAX_POINTS; i++) {
			const currPoint = i + 1;

			points.push({
				status:
					currPoint <= availablePoints
						? STATUS.available
						: currPoint <= requiredPoints
						? STATUS.required
						: STATUS.notNecessary,
			});
		}

		return points;
	}, [requiredPoints, availablePoints]);

	const PointsGroup = ({ status }: IPointsGroup) => {
		return (
			<Box sx={{ display: 'flex' }}>
				{points
					.filter(item => item.status === status)
					.map((point, index) => (
						<Box
							key={`point-${index}`}
							sx={{
								width: '20px',
								height: '7px',
								background: point.status,
								margin: '2px',
							}}
						/>
					))}
			</Box>
		);
	};

	const messageTwo = () => {
		const required = Math.floor(requiredPoints - availablePoints);

		if (required > 0) {
			return `Necesitas ${required} ${pluralize(
				'punto',
				required
			)} mas, solo tienes ${availablePoints}`;
		}

		return 'Este premio esta listo para ser canjeado';
	};

	const messageOne = () => {
		const required = Math.floor(requiredPoints - availablePoints);

		if (required > 0) {
			return `Tienes ${availablePoints} ${pluralize(
				'punto',
				requiredPoints
			)}, necesitas ${required} ${pluralize('punto', required)} mas`;
		}

		return 'Este premio esta listo para ser canjeado';
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Tooltip placement="top" arrow title={messageOne()}>
				<ButtonBase>
					<PointsGroup status={STATUS.available} />
				</ButtonBase>
			</Tooltip>
			<Tooltip arrow placement="top" title={messageTwo()}>
				<ButtonBase>
					<PointsGroup status={STATUS.required} />
				</ButtonBase>
			</Tooltip>
			<PointsGroup status={STATUS.notNecessary} />
		</Box>
	);
};

export default Points;

import { Box, Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Logo } from 'components';
import theme from 'theme';

const { spacing, palette } = theme;

const AuthLayout = () => {
	return (
		<Box sx={{ backgroundColor: palette.secondary.main }}>
			<Grid container sx={{ height: '100vh', marginTop: 0 }}>
				<Grid item xs={6} sx={{ display: 'flex', padding: spacing(6) }}>
					<img alt="Welcome" src="/login-bg.svg" width="550" />
				</Grid>
				<Grid item xs={6} sx={{ padding: spacing(6) }}>
					<Paper
						elevation={4}
						sx={{
							borderRadius: spacing(4),
							height: '100%',
							padding: spacing(10),
							boxSizing: 'border-box',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							maxWidth: spacing(87.5),
							paddingTop: spacing(3.5),
							paddingBottom: spacing(3.5),
						}}
					>
						<Box sx={{ display: 'flex', justifyContent: 'right' }}>
							<Logo sx={{ fontSize: '3rem' }} />
						</Box>
						<Outlet />
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AuthLayout;

import { useState, useEffect, useMemo } from 'react';
import { Typography, Paper, Stack, Container, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { User } from 'types/user';
import { Reward } from 'components';
import { grey } from '@mui/material/colors';
import { useQuery, useMutation } from 'react-query';
import { getRewards, getUserPoints, redeemUserPoints } from 'queries';
import { Reward as IReward } from 'types';
import { useAuth } from 'hooks';

const CustomerProfile = () => {
	const [userInfo, setUserInfo] = useState<User>();
	const location = useLocation();
	const { user } = useAuth();

	const rewardsQuery = useQuery(['rewards', user?.companyId], getRewards, {
		enabled: !!user?.companyId,
	});
	const pointsQuery = useQuery(
		['points', user?.companyId, userInfo?._id],
		getUserPoints,
		{
			enabled: !!user?.companyId && !!userInfo?._id,
		}
	);
	const pointsMutation = useMutation(redeemUserPoints);

	const handleRedeemReward = (rewardPoints: number) => {
		if ((pointsQuery.data ?? 0) >= rewardPoints) {
			if (confirm('Desea canjear este premio')) {
				pointsMutation.mutate({
					companyId: userInfo?._id,
					userId: userInfo?._id,
					points: rewardPoints,
				});
			}
		}
	};

	const availableRewards = useMemo(
		() =>
			(rewardsQuery.data ?? []).filter(
				(reward: IReward) => reward.points <= (pointsQuery.data ?? 0)
			),
		[rewardsQuery.data, pointsQuery.data]
	);

	const unavailableRewards = useMemo(
		() =>
			(rewardsQuery.data ?? []).filter(
				(reward: IReward) => reward.points > (pointsQuery.data ?? 0)
			),
		[rewardsQuery.data, pointsQuery.data]
	);

	useEffect(() => {
		setUserInfo(location.state);
	}, []);

	return (
		<Box sx={{ backgroundColor: grey[200] }}>
			<Container>
				<Typography variant="h2">
					{userInfo?.name} {userInfo?.lastName}
				</Typography>
				<br />

				<Paper sx={{ borderRadius: 6, padding: 3 }}>
					{availableRewards.length ? (
						<>
							<Typography variant="h3">Premios Disponibles</Typography>
							<Stack spacing={4}>
								{availableRewards.map((reward: IReward, index: number) => (
									<Reward
										key={`reward-${index}`}
										reward={reward}
										actions={[
											{
												children: 'Canjear',
												onClick: () => handleRedeemReward(reward.points),
											},
										]}
									/>
								))}
							</Stack>
						</>
					) : null}

					{unavailableRewards.length ? (
						<>
							<Typography variant="h3">No Disponibles</Typography>
							<Stack spacing={4}>
								{unavailableRewards.map((reward: IReward, index: number) => (
									<Reward
										key={`reward-${index}`}
										reward={reward}
										actions={[
											{
												children: 'Canjear',
												disabled: true,
											},
										]}
									/>
								))}
							</Stack>
						</>
					) : null}
				</Paper>
			</Container>
		</Box>
	);
};

export default CustomerProfile;

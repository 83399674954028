import { Box } from '@mui/material';
import Container, { ContainerProps } from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import NavigationBar from 'components/navigation-bar/NavigationBar';
import './main.css';

interface ICompanyLayout extends ContainerProps {
	hasNavbar?: boolean;
}

const CompanyLayout = ({ hasNavbar = true, ...props }: ICompanyLayout) => {
	return (
		<Box>
			{hasNavbar ? <NavigationBar /> : null}
			{props.fixed ? (
				<Container
					{...props}
					sx={{
						display: 'flex',
						padding: 8,
					}}
				>
					<Outlet />
				</Container>
			) : (
				<Outlet />
			)}
		</Box>
	);
};

export default CompanyLayout;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction } from 'react';
import { Box, ButtonBase, IconButton, Stack, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { Answers } from '../../../../types/surveys';
import theme from 'theme';

interface YesOrNoProps {
	answers: string[];
	questionId: string;
	setAnswers: Dispatch<SetStateAction<Answers>>;
}

const { palette } = theme;

const options = [
	{
		_id: 'yes',
		label: 'Si',
		icon: 'check',
		color: palette.secondary,
	},
	{
		_id: 'no',
		label: 'No',
		icon: 'close',
		color: palette.error,
	},
];

const YesOrNo = ({ answers, setAnswers, questionId }: YesOrNoProps) => {
	const selectedOption = answers[0] ?? '';

	return (
		<Stack gap={3}>
			{options.map(option => {
				const isSelected = selectedOption === option._id;

				return (
					<ButtonBase
						key={option.label}
						sx={{
							backgroundColor: isSelected ? option.color.main : 'none',
							display: 'flex',
							flexGrow: 1,
							height: 60,
							width: '100% !important',
							minWidth: '100% !important',
							border: 2,
							borderColor: isSelected ? option.color.main : option.color.light,
							borderRadius: 2,
							// Typography
							color: isSelected ? 'white' : `${option.color.main}`,
							fontSize: 16,
							justifyContent: 'space-between',
							p: 3,
						}}
						onClick={() => {
							setAnswers(answers => {
								const tempAnswers = { ...answers };
								tempAnswers[questionId] = [option._id];
								return tempAnswers;
							});
						}}
					>
						{option.label}
						{isSelected ? <Check /> : <Close />}
					</ButtonBase>
				);
			})}
		</Stack>
	);
};
export default YesOrNo;

import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
	showProgressBar: boolean;
	snackbar?: {
		message: string;
		severity: 'success' | 'error';
	};
}

const initialState: InitialState = {
	showProgressBar: false,
	snackbar: undefined,
};

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		showProgressBar: state => {
			state.showProgressBar = true;
		},
		hideProgressBar: state => {
			state.showProgressBar = false;
		},
		showSnackbar: (state, action) => ({
			...state,
			snackbar: action.payload.message,
		}),
		hideSnackbar: state => ({
			...state,
			snackbar: undefined,
		}),
	},
});

export const { showSnackbar, hideSnackbar, showProgressBar, hideProgressBar } =
	uiSlice.actions;

export default uiSlice.reducer;

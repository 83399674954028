import { useEffect } from 'react';
import { Typography, Skeleton, Button, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { verifyEmail } from 'queries/auth';
import useRenderControl from 'hooks/useRenderControl';

const EmailVerification = () => {
	const { emailToken } = useParams();
	const emailMutation = useMutation(verifyEmail);
	const { isFirstRender } = useRenderControl();

	useEffect(() => {
		if (isFirstRender && emailToken) {
			emailMutation.mutate(emailToken);
		}
	}, []);

	if (emailMutation.isLoading) {
		return (
			<Box sx={{ textAlign: 'center' }}>
				<Skeleton variant="text" sx={{ fontSize: '2rem' }} />
				<Skeleton variant="text" sx={{ fontSize: '1rem' }} />
			</Box>
		);
	}

	if (emailMutation?.error) {
		return <Typography variant="h1">Error validating email</Typography>;
	}

	return (
		<Box sx={{ textAlign: 'center' }}>
			<Typography variant="h1">{emailMutation.data?.message}</Typography>
			<Button
				startIcon={<ArrowBack />}
				component={Link}
				to="/iniciar-sesion"
				variant="contained"
				color="success"
				replace
			>
				Iniciar Sesión
			</Button>
		</Box>
	);
};

export default EmailVerification;

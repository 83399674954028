import { useDispatch } from 'store';
import { useMutation } from 'react-query';
import * as authQuery from 'queries/auth';
import * as authSlice from 'store/authSlice';
import { useSelector } from 'store/hooks';
import { User } from 'types/user';
import { useEffect, useMemo } from 'react';

const useAuth = (): {
	isLoggedIn: boolean;
	user: User | null;
	token: string | null;
	signIn: ({ user, token, refreshToken }: Partial<authSlice.AuthState>) => void;
	signOut: () => void;
	// initAuth: () => void;
} => {
	const dispatch = useDispatch();
	const { user, token, refreshToken } = useSelector(store => store.auth); // TODO: remove is logged in store

	const signOutMutation = useMutation(authQuery.signout, {
		onSuccess: () => {
			dispatch(authSlice.signOut());

			localStorage.removeItem('user');
			localStorage.removeItem('token');
			localStorage.removeItem('refreshToken');

			location.assign('/iniciar-sesion');
		},
	});

	const signIn = (payload: Partial<authSlice.AuthState>) => {
		const { user, token, refreshToken } = payload;

		if (!user || !token || !refreshToken) return;

		dispatch(authSlice.signIn({ user, token, refreshToken }));

		localStorage.setItem('user', JSON.stringify(user));
		localStorage.setItem('token', token);
		localStorage.setItem('refreshToken', refreshToken);
	};

	const signOut = () => {
		signOutMutation.mutate();
	};

	// Handles redux store
	useEffect(() => {
		if (!user ?? !token ?? !refreshToken) {
			const user = localStorage.getItem('user');
			const token = localStorage.getItem('token');
			const refreshToken = localStorage.getItem('refreshToken');

			if (user && token && refreshToken) {
				dispatch(
					authSlice.signIn({
						user: JSON.parse(user ?? ''),
						token,
						refreshToken,
					})
				);
			}
		}
	}, [user, token, refreshToken]);

	// Handles local store
	const isLoggedIn = useMemo((): boolean => {
		const user = localStorage.getItem('user');
		const token = localStorage.getItem('token');
		const refreshToken = localStorage.getItem('refreshToken');

		if (user && token && refreshToken) {
			return true;
		}

		return false;
	}, [user, token, refreshToken]);

	return {
		isLoggedIn,
		user,
		token,
		signIn,
		signOut,
		// initAuth,
	};
};

export default useAuth;

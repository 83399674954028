import { useMemo, useState, MouseEvent } from 'react';
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	Avatar,
	Button,
	MenuItem,
	InputAdornment,
	TextField,
	Divider,
} from '@mui/material';
import Logo from 'components/logo/Logo';
import { getInitials } from 'utils/getInitials';
import { stringToColor } from 'utils/stringToColor';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Search as SearchIcon, Menu as MenuIcon } from '@mui/icons-material';
import theme from 'theme';

const NavigationBar = () => {
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { user, signOut } = useAuth();
	const navigate = useNavigate();

	const fullName = useMemo(
		() => `${user?.name ?? ''} ${user?.lastName ?? ''}`,
		[user?.name, user?.lastName]
	);

	const menuItems = () => {
		const menu = [
			{
				label: 'Inicio',
				href: '/',
				roles: ['customer', 'admin'],
			},
			{
				label: 'Colectar Puntos',
				href: '/user/colectar-puntos',
				roles: ['customer', 'admin'],
				requiresAuth: true,
			},
			{
				label: 'Mis Puntos',
				href: '/user/mis-puntos',
				roles: ['customer', 'admin'],
				requiresAuth: true,
			},
			{
				label: 'Crear Cuenta',
				href: '/crear-cuenta',
				roles: ['customer', 'admin'],
				hideOnAuth: true,
			},
			{
				label: 'Iniciar Sesión',
				roles: ['customer', 'admin'],
				href: '/iniciar-sesion',
				hideOnAuth: true,
			},
		];

		const tempMenu: any[] = [];

		menu.forEach(item => {
			// AUTHENTICATED USERS
			if (
				user &&
				!item?.hideOnAuth &&
				item.roles.includes(user?.role ? user.role[0] : '')
			) {
				tempMenu.push(item);
			}
			// PUBLIC
			if (!user && !item.requiresAuth) {
				tempMenu.push(item);
			}
		});

		return tempMenu;
	};

	const userMenuItems = useMemo(
		() => [
			{
				label: 'Perfil',
				href: '/perfil',
				roles: ['customer', 'admin'],
			},
			{
				label: 'Divider',
			},
			{
				label: 'Mi Página',
				href: '/administracion',
				roles: ['admin'],
			},
			{
				label: 'Crear Puntos',
				href: '/admin/crear-puntos',
				roles: ['admin'],
			},
			{
				label: 'Buscar Cliente',
				href: '/admin/clientes',
				roles: ['admin'],
			},
			{
				label: 'Divider',
			},
			{
				label: 'Cerrar Sesión',
				fn: signOut,
			},
		],
		[signOut]
	);

	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleMenu = (page: any) => {
		if (page.href) {
			navigate(page.href);
		}
		if (page.fn) {
			page.fn();
		}
	};

	return (
		<AppBar
			position="static"
			color="inherit"
			sx={{
				height: 75,
				justifyContent: 'center',
				background: `linear-gradient(-45deg, rgba(255,255,255,1) 70%, ${theme.palette.primary.main} 70%)`,
				'& .MuiButton-text': {
					fontWeight: 400,
					fontSize: '1rem',
					textTransform: 'capitalize',
				},
			}}
			elevation={2}
		>
			<Toolbar disableGutters sx={{ px: theme.spacing(2) }}>
				<Logo color="white" sx={{ mr: theme.spacing(2) }} />
				<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: 'block', md: 'none' },
						}}
					>
						{menuItems().map((page, index) => (
							<MenuItem key={`menu-item-${index}`} onClick={handleCloseNavMenu}>
								<Typography textAlign="center">{page.label}</Typography>
							</MenuItem>
						))}
					</Menu>
				</Box>
				<TextField
					placeholder="Que quires hacer hoy?"
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						),
						sx: { borderRadius: 1.5, backgroundColor: theme.palette.common.white },
					}}
				/>
				<Box
					sx={{
						flexGrow: 1,
						width: '100%',
						justifyContent: 'flex-end',
						display: { xs: 'none', md: 'flex' },
					}}
				>
					{menuItems().map((page, index) => (
						<Button
							variant="text"
							key={`menu-item-${index}`}
							sx={{ my: 2, color: 'black', display: 'block' }}
							onClick={() => {
								handleMenu(page);
								setAnchorElNav(null);
							}}
						>
							{page.label}
						</Button>
					))}
				</Box>

				<Box sx={{ flexGrow: 0 }}>
					{user ? (
						<>
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar
									alt={fullName}
									src={user?.picture ?? ''}
									sx={{ backgroundColor: stringToColor(fullName), width: 60, height: 60 }}
								>
									{getInitials(fullName)}
								</Avatar>
							</IconButton>
							<Menu
								sx={{ mt: theme.spacing(8) }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={() => setAnchorElUser(null)}
							>
								{userMenuItems.map((page, index) =>
									page.label === 'Divider' ? (
										<Divider key={`user-menu-item-${index}`} />
									) : (
										<MenuItem
											key={`user-menu-item-${index}`}
											onClick={() => {
												handleMenu(page);
												setAnchorElUser(null);
											}}
										>
											<Typography textAlign="center">{page.label}</Typography>
										</MenuItem>
									)
								)}
							</Menu>
						</>
					) : null}
				</Box>
			</Toolbar>
		</AppBar>
	);
};
export default NavigationBar;

import axios from 'axios';
import { store } from 'store/store';
import { API_URL } from './env';

export const request = axios.create({
	baseURL: API_URL,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

// Request interceptor
request.interceptors.request.use(
	async req => {
		store.dispatch({ type: 'ui/showProgressBar' });
		const token = localStorage.getItem('token');
		const refreshToken = localStorage.getItem('refreshToken');

		req.headers.Authorization = token ? `Bearer ${token}` : '';
		req.headers.refreshToken = refreshToken ?? '';

		return req;
	},
	err => {
		store.dispatch({ type: 'ui/hideProgressBar' });
		return err;
	}
);

// Response interceptor
request.interceptors.response.use(
	res => {
		store.dispatch({ type: 'ui/hideProgressBar' });

		const snackbar = res.data.snackbar;

		if (snackbar) {
			store.dispatch({
				type: 'ui/showSnackbar',
				payload: snackbar,
			});
		}

		return res.data;
	},
	async err => {
		store.dispatch({ type: 'ui/hideProgressBar' });

		if (err?.response?.status === 401) {
			// const storedToken = localStorage.getItem('token') ?? '';
			const storedRefreshToken = localStorage.getItem('refreshToken') ?? '';

			// eslint-disable-next-line no-useless-catch
			try {
				const rs = await axios.post(`${API_URL}/refresh-token`, {
					refresh_token: storedRefreshToken,
				});

				const { token, user } = rs.data;

				localStorage.setItem('token', JSON.stringify(token));
				localStorage.setItem('user', JSON.stringify(user));

				return;
			} catch (err) {
				store.dispatch({ type: 'user/reset' });
				localStorage.removeItem('user');
				throw err;
			}
		}

		store.dispatch({
			type: 'ui/showSnackbar',
			payload: {
				message: err?.response?.data?.message || err?.response?.message || err?.message, // TODO: simplify this
				severity: 'error',
			},
		});
		throw err;
	}
);

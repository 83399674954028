import { Field } from 'formik';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';

interface Option {
	label: string;
	value: string | number;
}

interface SelectInputProps {
	label: string;
	name: string;
	options: Option[];
}

const SelectInput = ({
	label,
	options,
	name,
	...rest
}: SelectInputProps & TextFieldProps) => {
	return (
		<Field name={name}>
			{({ field, meta }: { field: any; form: any; meta: any }) => {
				const { error, touched } = meta;
				return (
					<FormControl fullWidth>
						<InputLabel id={name}>{label}</InputLabel>
						<Select
							labelId={name}
							id={name}
							label={label}
							error={touched && !!error}
							helpertext={touched ? error : ''}
							{...field}
							{...rest}
						>
							{options.map((o, i) => (
								<MenuItem key={`option-${i}`} value={o.value}>
									{o.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			}}
		</Field>
	);
};

export default SelectInput;

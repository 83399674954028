import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/user';

export interface AuthState {
	isLoggedIn: boolean;
	user: User | null;
	token: string | null;
	refreshToken: string | null;
}

const initialState: AuthState = {
	isLoggedIn: false,
	user: null,
	token: null,
	refreshToken: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signIn: (state, action: PayloadAction<Omit<AuthState, 'isLoggedIn'>>) => ({
			...state,
			...action.payload,
			isLoggedIn: true,
		}),
		signOut: () => initialState,
		updateUser: (state, action: PayloadAction<User>) => ({
			...state,
			user: action.payload,
		}),
	},
});

export const { signIn, signOut, updateUser } = authSlice.actions;
export const userSelector = (state: any) => state.user;

export default authSlice.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Card,
	CardActions,
	CardMedia,
	CardContent,
	Typography,
	Box,
} from '@mui/material';
import { Button } from 'components';
import { ButtonProps } from '@mui/material/Button';
import { IButton } from 'components/button/Button';
import { Reward as IReward } from 'types';
import Points from 'components/points/Points';

interface RewardProps {
	reward: IReward;
	actions?: Array<ButtonProps & IButton>;
	availablePoints?: number;
}

const Reward = ({ reward, availablePoints, actions = [] }: RewardProps) => {
	return (
		<Card
			className="reward"
			elevation={0}
			sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}
		>
			<CardMedia
				component="img"
				src={(reward.image as string) ?? '/placeholder.png'}
				alt={reward.description}
				height="100%"
				sx={{ maxHeight: '150px', maxWidth: '300px', borderRadius: 4 }}
			/>
			<CardContent sx={{ padding: '0px !important', marginLeft: 4, flexGrow: 1 }}>
				<Typography gutterBottom variant="h3">
					{reward.title}
				</Typography>
				<Typography gutterBottom variant="body1" color="text.secondary">
					{reward.description}
				</Typography>
				<Box sx={{ marginTop: 2, marginBottom: 2 }}>
					<Points availablePoints={availablePoints ?? 0} requiredPoints={reward.points} />
				</Box>
				<CardActions sx={{ padding: 0 }}>
					{actions
						? actions.map((buttonProps, index) => (
								<Button
									key={`action-button-${index}`}
									variant="outlined"
									size="small"
									color="secondary"
									{...buttonProps}
								/>
						  ))
						: null}
				</CardActions>
			</CardContent>
		</Card>
	);
};

export default Reward;

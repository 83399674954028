// Surveys
export const companySurveysUrl = (companyId: string = ''): string =>
	`/comercios/${companyId}/encuestas`;

export const companySurveyUrl = (companyId: string = '', surveyId: string = ''): string =>
	`/comercios/${companyId}/encuestas/${surveyId}`;

export const companySurveyLobbyUrl = (
	companyId: string = '',
	surveyId: string = ''
): string => `/comercios/${companyId}/encuestas/${surveyId}/encuesta`;

export const companySurveyCheckOutUrl = (
	companyId: string = '',
	surveyId: string = ''
): string => `/comercios/${companyId}/encuestas/${surveyId}/encuesta/listo`;

// Rewards
export const companyRewardsUrl = (companyId: string = ''): string =>
	`/comercios/${companyId}/premios`;

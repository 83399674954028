/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Typography,
	Paper,
	Skeleton,
	Box,
	Stack,
	Grid,
	List,
	ListItem,
	Container,
	ListItemText,
	ListItemIcon,
	Tabs,
	Tab,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CheckCircle } from '@mui/icons-material';
import theme from '../../theme';
import { CompanyContext } from './CompanyProvider';
import { companyRewardsUrl, companySurveysUrl } from 'constants/urls';

interface LinkTabProps {
	label?: string;
	href: string;
}

const tabsList: { [key: string]: number } = {
	premios: 0,
	encuestas: 1,
};

const CompanyProfile = () => {
	const navigate = useNavigate();
	const { companyId } = useParams();
	const { pathname } = useLocation();

	const { company, points, isLoading } = useContext(CompanyContext);
	const [activeTab, setActiveTab] = useState(tabsList[pathname.split('/')[3]] ?? 0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const LinkTab = (props: LinkTabProps) => {
		return (
			<Tab
				component="a"
				onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
					event.preventDefault();
					navigate(props.href);
				}}
				{...props}
			/>
		);
	};

	const image = '/medal.png';

	return (
		<Box sx={{ backgroundColor: grey[200], display: 'flex' }}>
			<Container
				maxWidth={false}
				sx={{
					marginTop: 4,
				}}
			>
				<Grid container spacing={4} height={'100vh'}>
					{/* Left Sidebar */}
					<Grid item sm={3} sx={{ backgroundColor: 'white' }}>
						<Stack px={2} py={3} alignItems={'center'} gap={2}>
							<img src={company?.logo} width="120" height="115" />
							<Typography variant="h2">
								{isLoading ? <Skeleton /> : company?.name}
							</Typography>
							<Typography variant="body1" color={'#333333'}>
								{isLoading ? <Skeleton /> : company?.category?.title}
							</Typography>
						</Stack>

						<Stack>
							<List>
								<ListItem>
									<ListItemText primary="Estatus:" />
									<ListItemText sx={{ textAlign: 'right' }} secondary="Bronce" />
								</ListItem>
								<ListItem>
									<ListItemText primary="Puntos Acumulados:" />
									<ListItemText sx={{ textAlign: 'right' }} secondary={points} />
								</ListItem>
								<ListItem>
									<ListItemText primary="Sucursales:" />
									<ListItemText sx={{ textAlign: 'right' }} secondary="7" />
								</ListItem>
							</List>
						</Stack>
					</Grid>
					{/* Main */}
					<Grid item sm={6} sx={{ paddingTop: '0px !important' }}>
						<Stack gap={1.5}>
							<Box sx={{ width: '100%' }}>
								<Tabs
									value={activeTab}
									onChange={handleChange}
									aria-label="nav tabs example"
								>
									<LinkTab label="Premios" href={companyRewardsUrl(companyId)} />
									<LinkTab label="Encuestas" href={companySurveysUrl(companyId)} />
								</Tabs>
							</Box>
							<Outlet />
						</Stack>
					</Grid>

					{/* Right SideBar */}
					<Grid item sm={3} mt={4}>
						<Paper sx={{ borderRadius: 6, padding: 3, gap: 2 }}>
							<Stack gap={2} alignItems="center" textAlign="center">
								<img src={image ?? '/placeholder.png'} width="110" height="148" />
								<Typography fontWeight={300}>
									Estas a un paso de tu
									<br />
									<Typography variant="h2" component="span">
										Primera Medalla!
									</Typography>
									<br />
									Completa las metas faltantes para obtener muchos beneficios:
								</Typography>
							</Stack>
							<Stack>
								<List>
									<ListItem>
										<ListItemIcon sx={{ color: theme.palette.secondary.main }}>
											<CheckCircle />
										</ListItemIcon>
										<ListItemText primary="Haz tu primera compra" />
									</ListItem>
									<ListItem>
										<ListItemIcon sx={{ color: theme.palette.secondary.main }}>
											<CheckCircle />
										</ListItemIcon>
										<ListItemText primary="Refiere a una persona" />
									</ListItem>
								</List>
							</Stack>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default CompanyProfile;

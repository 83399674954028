import { Button as MuiButton, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

export interface IButton extends ButtonProps {
	isLoading?: boolean;
}

const Button = ({ isLoading = false, endIcon, ...rest }: IButton) => {
	return (
		<MuiButton
			endIcon={
				isLoading ? (
					<CircularProgress
						size={20}
						sx={{
							color: 'white',
							display: isLoading ? 'block' : 'none',
						}}
					/>
				) : (
					endIcon
				)
			}
			disableElevation
			{...rest}
		/>
	);
};

export default Button;

export const typography = {
	defaultProps: {},
	styleOverrides: {
		root: {
			fontFamily: 'Poppins',
		},
		h1: {
			fontSize: '2.5rem',
			fontWeight: 700,
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 500,
		},
		h3: {
			fontSize: '1.2rem',
			fontWeight: 400,
		},
		h4: {
			fontSize: '1rem',
			fontWeight: 300,
		},
	},
};

export default typography;

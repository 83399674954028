export const card = {
	styleOverrides: {
		root: {
			borderRadius: 16,
			padding: '16px 0',
			display: 'flex',
			flexDirection: 'column' as const,
			gap: 16,
		},
	},
};

export const cardMedia = {
	styleOverrides: {
		root: { marginTop: '-16px' },
	},
};

export const cardContent = {
	styleOverrides: {
		root: {
			gap: '16px',
			padding: '0 16px',
			paddingBottom: '0px !important',
		},
	},
};

export const cardActions = {
	styleOverrides: {
		root: {
			display: 'flex',
			alignItems: 'center',
			padding: '0 16px',

			'.MuiButtonBase-root': {
				justifyContent: 'space-between',
			},
			'.MuiSvgIcon-root': {
				fontSize: '2rem',
			},
		},
	},
};

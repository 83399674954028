/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from 'utils/axios';
import { User } from 'types/user';

export const getUserById = async ({ queryKey }: any): Promise<User> => {
	const [_key, userId] = queryKey;
	return await request.get(`/users/${userId}`);
};

export const getUserByPhone = async ({ queryKey }: any): Promise<User> => {
	const [_key, userPhone] = queryKey;
	return await request.get(`/users/phone/${userPhone}`);
};

export const createUser = async (payload: User): Promise<User> => {
	return await request.post('/signup', payload);
};

export const updateUser = async (payload: User): Promise<User> => {
	return await request.put(`/users/${payload._id}`, payload);
};

import { Stack, Typography } from '@mui/material';

interface DetailInputProps {
	label: string;
	content: string;
}

const DetailInput = ({ label, content }: DetailInputProps) => {
	return (
		<Stack spacing={1} sx={{ width: '100%' }}>
			<Typography sx={{ fontSize: '12px', color: '#828282', fontWeight: '400' }}>
				{label}
			</Typography>
			<Typography
				sx={{
					fontSize: '18px',
					color: '#333333',
					fontWeight: '400',
					paddingBottom: '16px',
				}}
			>
				{content}
			</Typography>
		</Stack>
	);
};

export default DetailInput;

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Paper, Stack, InputAdornment, Box } from '@mui/material';
import {
	ArrowRightAlt as ArrowRightAltIcon,
	ArrowBack as ArrowBackIcon,
	ReceiptLongOutlined,
} from '@mui/icons-material';
import { TextInput, Button } from 'components';
import { useMutation } from 'react-query';
import { createRedeemCode } from 'queries/redeemCodes';
import { Point } from 'types/point';
import theme from 'theme';
import { useAuth } from 'hooks';

const ValidationSchema = Yup.object().shape({
	amount: Yup.number().required('Campo Requerido'),
});

export const CreatePoints = () => {
	const { user } = useAuth();
	const createPointMutation = useMutation(createRedeemCode);

	const onSubmit = (values: { amount: any }) => {
		if (user == null) return;

		const point: Point = {
			amount: Number(values.amount || 0),
			companyId: user?.companyId ?? '',
		};

		createPointMutation.mutate(point);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				justifyContent: 'center',
			}}
		>
			<Paper
				elevation={4}
				sx={{
					borderRadius: theme.spacing(3),
					padding: theme.spacing(8, 6),
					width: 500,
				}}
			>
				<Typography sx={{ marginBottom: 4 }} variant="h1">
					Registrar Compra
				</Typography>
				<Formik
					initialValues={{ amount: '' }}
					validationSchema={ValidationSchema}
					onSubmit={onSubmit}
				>
					{({ handleReset }: any) => {
						return createPointMutation.data ? (
							<div>
								<Paper
									elevation={3}
									sx={{
										padding: theme.spacing(2),
										textAlign: 'center',
									}}
								>
									<Typography variant="h3">Tu código es:</Typography>
									<Typography variant="h2">{createPointMutation.data?.code}</Typography>
								</Paper>
								<br />
								<Button
									startIcon={<ArrowBackIcon />}
									onClick={() => {
										handleReset();
										createPointMutation.reset();
									}}
								>
									Regresar
								</Button>
							</div>
						) : (
							<Form>
								<Stack spacing={4} alignItems="center">
									<TextInput
										fullWidth
										name="amount"
										label="Monto"
										type="number"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<ReceiptLongOutlined />
												</InputAdornment>
											),
										}}
									/>
									<Button
										sx={{ justifyContent: 'space-between' }}
										fullWidth
										isLoading={createPointMutation.isLoading}
										endIcon={<ArrowRightAltIcon />}
										type="submit"
									>
										Generar Código
									</Button>
								</Stack>
							</Form>
						);
					}}
				</Formik>
			</Paper>
		</Box>
	);
};

export default CreatePoints;

import { West } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { Button } from 'components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../CompanyProvider';
import pluralize from 'pluralize';
import theme from 'theme';
import { companySurveysUrl } from 'constants/urls';

const CompanySurveyCheckout = () => {
	const { company, activeSurvey } = useContext(CompanyContext);
	const navigate = useNavigate();
	const { palette } = theme;

	return (
		<Grid container height="100vh">
			<Grid
				item
				sm={5}
				p={6}
				bgcolor={palette.primary.main}
				sx={{
					gap: 2,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Stack flex={1} alignItems={'center'} justifyContent={'center'}>
					<Typography fontSize={24} color={'white'}>
						Acumulaste +
						<strong>
							{activeSurvey?.points} {pluralize('PTO')}{' '}
						</strong>
					</Typography>
					<Typography fontSize={96} variant="h1" color={'white'}>
						Gracias!
					</Typography>
					<Typography fontSize={24} color={palette.primary.light}>
						Tu opinión es
						<strong> muy importante </strong>
						para nosotros
					</Typography>
				</Stack>
				<Stack>
					<Button
						variant="outlined"
						startIcon={<West />}
						onClick={() => navigate(companySurveysUrl(company?._id))}
						sx={{ borderColor: 'white', color: 'white' }}
					>
						Regresar
					</Button>
				</Stack>
			</Grid>
			<Grid
				item
				sm={7}
				sx={{
					backgroundImage: `url(${activeSurvey?.image ?? '/placeholder.png'})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			></Grid>
		</Grid>
	);
};

export default CompanySurveyCheckout;

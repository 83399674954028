import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Stack, Paper, InputAdornment, Box } from '@mui/material';
import { Button, TextInput, Dialog } from 'components';
import {
	ArrowRightAlt as ArrowRightAltIcon,
	ReceiptLongOutlined,
} from '@mui/icons-material';
import Lottie from 'lottie-react';
import giftAnimation from './90020-gift.json';
import pluralize from 'pluralize';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { createPoints } from 'queries/points';
import theme from 'theme';
import { companyRewardsUrl } from 'constants/urls';

const ValidationSchema = Yup.object().shape({
	code: Yup.string().min(4, 'Muy corto').max(4, 'Muy Largo').required('Campo requerido'),
});

const RedeemPoints = () => {
	const navigate = useNavigate();
	const redeemCodeMutation = useMutation(createPoints);

	const onSubmit = (values: { code: any }) => {
		redeemCodeMutation.mutate(values.code);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				justifyContent: 'center',
			}}
		>
			<Paper
				elevation={4}
				sx={{
					borderRadius: theme.spacing(3),
					paddingTop: theme.spacing(8),
					paddingBottom: theme.spacing(8),
					paddingLeft: theme.spacing(6),
					paddingRight: theme.spacing(6),
					width: 500,
				}}
			>
				<Stack spacing={4}>
					<Typography variant="h1">Canjear Puntos</Typography>
					<Formik
						initialValues={{ code: '' }}
						validationSchema={ValidationSchema}
						onSubmit={onSubmit}
					>
						{({ handleReset }: any) => {
							return (
								<>
									<Form>
										<Stack spacing={4} alignItems="center">
											<TextInput
												fullWidth
												name="code"
												label="Código"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<ReceiptLongOutlined />
														</InputAdornment>
													),
												}}
											/>
											<Button fullWidth type="submit" endIcon={<ArrowRightAltIcon />}>
												Obtener Puntos
											</Button>
										</Stack>
									</Form>
									<Dialog
										open={!!redeemCodeMutation.data}
										onClose={() => {
											handleReset();
											redeemCodeMutation.reset();
										}}
										maxWidth="sm"
										actions={[
											{
												fullWidth: true,
												endIcon: <ArrowRightAltIcon />,
												isLoading: redeemCodeMutation.isLoading,
												onClick: () => {
													if (redeemCodeMutation.data?.company._id) {
														navigate(
															companyRewardsUrl(redeemCodeMutation.data?.company._id)
														);
													}
												},
												children: `Continuar a ${redeemCodeMutation.data?.company.name}`,
												sx: { justifyContent: 'space-between' },
											},
										]}
									>
										<Stack sx={{ textAlign: 'center', padding: 4 }}>
											<Lottie
												animationData={giftAnimation}
												loop={false}
												style={{
													width: 315,
													height: 280,
													margin: 'auto',
												}}
											/>
											<Typography
												variant="h1"
												color="secondary"
												sx={{
													marginTop: '0px !important',
													fontFamily: 'mitr',
													fontSize: 64,
												}}
											>
												¡Felicidades!
											</Typography>
											<Typography
												variant="h3"
												sx={{
													marginTop: '0px !important',
													fontFamily: 'poppins',
													fontSize: 38,
												}}
											>
												Sumaste {redeemCodeMutation.data?.createdPoints}{' '}
												{pluralize('Punto', redeemCodeMutation.data?.createdPoints)}
											</Typography>
										</Stack>
									</Dialog>
								</>
							);
						}}
					</Formik>
				</Stack>
			</Paper>
		</Box>
	);
};

export default RedeemPoints;

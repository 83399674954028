/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Grid, Stack, Typography, Button } from '@mui/material';
import { CompanyContext } from '../CompanyProvider';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { East, West } from '@mui/icons-material';
import BoxInfo from './components/BoxInfo';
import pluralize from 'pluralize';
import { GoBack } from 'components';
import { companySurveyLobbyUrl } from 'constants/urls';

const CompanySurveyLobby = () => {
	const { company, activeSurvey } = useContext(CompanyContext);

	if (!activeSurvey) {
		return null;
	}

	return (
		<Grid container height="100vh">
			<Grid item md={6} p={4} sx={{ display: 'flex', flexDirection: 'column' }}>
				<Stack gap={2} flex={1}>
					<Typography variant="body1">{company?.name}</Typography>
					<img src={company?.logo} width={100} height={100} />
					<Typography variant="h1">{activeSurvey?.title}</Typography>
					<Divider />
					<Typography variant="h3">{activeSurvey?.description}</Typography>
					<Stack direction={'row'} gap={8} m={2}>
						<BoxInfo title={activeSurvey?.estimatedTime} subtitle={'Minutos'} />
						<BoxInfo title={activeSurvey?.questions.length} subtitle={'Preguntas'} />
						<BoxInfo title={activeSurvey?.points} subtitle={pluralize('pto')} />
					</Stack>
				</Stack>
				<Stack direction={'row'} gap={4} justifyContent={'space-between'}>
					<GoBack fullWidth icon={<West />} sx={{ my: 4 }} />
					<Button
						fullWidth
						component={Link}
						endIcon={<East />}
						to={companySurveyLobbyUrl(company?._id, activeSurvey?._id)}
						sx={{ my: 4 }}
					>
						Iniciar
					</Button>
				</Stack>
			</Grid>
			<Grid
				item
				md={6}
				sx={{
					backgroundImage: `url(${activeSurvey.image ?? '/placeholder.png'})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			></Grid>
		</Grid>
	);
};

export default CompanySurveyLobby;

import { Outlet } from 'react-router-dom';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { AccountCircleOutlined, LockOpenOutlined } from '@mui/icons-material';
import { Menu } from 'components';
import { useSelector } from 'store';
import { getInitials } from 'utils/getInitials';
import stringToColor from 'utils/stringToColor';

const ProfileLayout = () => {
	const fullName = useSelector(
		store => `${store.auth.user?.name ?? ''} ${store.auth.user?.lastName ?? ''}`
	);

	return (
		<Box>
			<Grid container spacing={4}>
				<Grid
					container
					direction="row"
					xs={12}
					sx={{ alignItems: 'center', gap: '48px' }}
				>
					<Avatar
						alt={fullName}
						// src={user.picture ?? ''}
						sx={{
							fontSize: '32px',
							backgroundColor: stringToColor(fullName),
							width: 100,
							height: 100,
						}}
					>
						{getInitials(fullName)}
					</Avatar>
					<Typography variant="h1">{fullName}</Typography>
				</Grid>
				<Grid item xs={4}>
					<Menu
						title="Configuraciones"
						items={[
							{
								label: 'Perfil',
								icon: <AccountCircleOutlined />,
								url: '/perfil',
							},
							{
								label: 'Seguridad',
								icon: <LockOpenOutlined />,
								url: '/perfil/cambiar-contrasena',
							},
						]}
					/>
				</Grid>
				<Grid item xs={8}>
					<Outlet />
				</Grid>
			</Grid>
		</Box>
	);
};
export default ProfileLayout;

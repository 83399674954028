/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Grid, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { Button, GoBack } from 'components';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { CompanyContext } from '../CompanyProvider';
import { DoorBackOutlined, East, West } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import MultipleSelection from './templates/MultipleSelection';
import YesOrNo from './templates/YesOrNo';
import { AnswerOption, Answers, SurveyQuestion } from 'types/surveys';
import theme from '../../../theme/';
import SingleSelection from './templates/SingleSelection';
import { respondSurvey } from 'queries/surveys';
import { useMutation } from 'react-query';
import { companySurveyCheckOutUrl } from 'constants/urls';

interface TemplateProps {
	type: string;
	answers: string[];
	setAnswers: Dispatch<SetStateAction<Answers>>;
	options: AnswerOption[];
	maxAnswers: number;
	questionId: string;
}

const Template = (props: TemplateProps) => {
	switch (props.type) {
		case 'Yes/No':
			return <YesOrNo {...props} />;
		case 'Multiple Selection':
			return <MultipleSelection {...props} />;
		default:
			return <SingleSelection {...props} />;
	}
};

const CompanySurvey = () => {
	const { activeSurvey, company } = useContext(CompanyContext);
	const [activeQuestion, setActiveQuestion] = useState(0);
	const [answers, setAnswers] = useState<Answers>({});
	const [isLeaving, setIsLeaving] = useState(false);
	const navigate = useNavigate();
	const { palette } = theme;

	const respondMutation = useMutation(respondSurvey, {
		onSuccess: () => {
			setIsLeaving(true);
			navigate(companySurveyCheckOutUrl(company?._id, activeSurvey?._id));
		},
		onError: err => {
			console.log(err);
		},
	});

	const onSubmit = () => {
		respondMutation.mutate({
			companyId: company?._id ?? '',
			surveyId: activeSurvey?._id ?? '',
			answers,
		});
	};

	const question = useMemo(() => {
		if (
			!activeSurvey?.questions ||
			activeSurvey?.questions?.length < 0 ||
			!activeSurvey?.questions[activeQuestion]
		) {
			return null;
		}

		return activeSurvey?.questions[activeQuestion];
	}, [activeSurvey?.questions, activeQuestion]);

	const isLastQuestion = useMemo(() => {
		if (activeSurvey && activeQuestion === activeSurvey?.questions?.length - 1) {
			return true;
		}
		return false;
	}, [activeSurvey?.questions, activeQuestion]);

	const previousQuestion = () => {
		if (activeQuestion > 0) {
			setActiveQuestion(activeQuestion - 1);
		}
	};

	const nextQuestion = () => {
		if (!isLastQuestion) {
			setActiveQuestion(activeQuestion + 1);
		}
	};

	const questionId = question?._id ?? '';
	const currAnswers = answers[questionId] ?? [];
	const questionType = question?.type ?? 'Multiple Selection';

	const canContinue = () => {
		const currentQuestion = activeSurvey?.questions[activeQuestion];
		const currentAnswer = currAnswers;
		const hasEnoughAnswers =
			currentAnswer.length >= (currentQuestion?.minAnswers as unknown as number);
		return hasEnoughAnswers;
	};
	const progress = useMemo(() => {
		let percentage = 0;
		const stepValue = 100 / (activeSurvey?.questions?.length ?? 0);

		// si puede continuar
		// incluir la pagina actual el porcentaje
		if (canContinue()) {
			percentage = stepValue * (activeQuestion + 1);
		} else {
			// si no puede continuar
			// incluir la pagina anterior en el porcentaje
			percentage = stepValue * activeQuestion;
		}

		return percentage;
	}, [activeSurvey?.questions, activeQuestion, canContinue]);

	return (
		<Grid container height="100vh">
			<Grid item sm={4} p={4} bgcolor={palette.primary.main}>
				<Typography
					variant="h4"
					textTransform={'uppercase'}
					color={palette.primary.light}
					fontSize={18}
					sx={{ my: 2 }}
				>
					{company?.name} - Encuesta:
					<br />
					{activeSurvey?.title}
				</Typography>
				<Stack gap={2} alignItems={'flex-end'}>
					<Divider color={palette.primary.light} sx={{ width: '100%' }} />
					<Typography color={'white'} fontSize={32} textAlign={'right'}>
						{activeQuestion + 1}/{activeSurvey?.questions.length}
					</Typography>
					<Divider color={palette.primary.light} sx={{ width: '100%' }} />
					<Typography color={'white'} variant="h1" textAlign={'right'}>
						{question ? question?.title : null}
					</Typography>
					<GoBack
						icon={<DoorBackOutlined />}
						label="Salir"
						sx={{ color: 'white', borderColor: 'white' }}
					/>
				</Stack>
			</Grid>
			<Grid
				item
				sm={8}
				sx={{
					padding: 4,
					gap: 2,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Stack height={30} alignItems={'flex-end'}>
					<LinearProgress
						value={progress}
						variant="determinate"
						color="secondary"
						sx={{ width: '30%', height: '50%', borderRadius: 30 }}
					/>
				</Stack>
				<Stack>
					<Typography fontSize={14} fontWeight={700} color={'#828282'}>
						Instrucciones:
					</Typography>
					<Typography fontSize={20} fontWeight={300} color={'#828282'}>
						{question ? question?.description : null}
					</Typography>
				</Stack>
				<Grid container justifyContent="center" alignItems={'center'}>
					<Grid item md={6}>
						<Template
							type={questionType}
							answers={currAnswers}
							setAnswers={setAnswers}
							options={question?.answerOptions ?? []}
							maxAnswers={Number(question?.maxAnswers) ?? 1}
							questionId={question?._id ?? ''}
						/>
					</Grid>
				</Grid>
				<Stack gap={4} direction={'row'} justifyContent={'space-between'}>
					<Button
						fullWidth
						startIcon={<West />}
						onClick={previousQuestion}
						variant="outlined"
					>
						Regresar
					</Button>
					{isLastQuestion ? (
						<Button
							fullWidth
							endIcon={<East />}
							onClick={onSubmit}
							color="secondary"
							disabled={!canContinue()}
						>
							Finalizar
						</Button>
					) : (
						<Button
							onClick={nextQuestion}
							endIcon={<East />}
							color="secondary"
							fullWidth
							disabled={!canContinue()}
						>
							Continuar
						</Button>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
};

export default CompanySurvey;

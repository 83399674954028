import {
	ListItemIcon,
	ListItemText,
	ListItem,
	MenuList,
	Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface MenuItemProps {
	label: string;
	icon: ReactNode;
	url: string;
	type?: string;
}

interface MenuProps {
	title: string;
	items: MenuItemProps[];
}

const Menu = ({ title, items }: MenuProps) => {
	const location = useLocation();

	return (
		<>
			<Typography variant="h3" component="h1">
				{title}
			</Typography>
			<MenuList>
				<>
					{items.map((item, index) => {
						return (
							<ListItem
								key={`item-${index}`}
								to={item.url}
								component={Link}
								selected={location.pathname === item.url}
							>
								<ListItemText>{item.label}</ListItemText>
								<ListItemIcon>{item.icon}</ListItemIcon>
							</ListItem>
						);
					})}
				</>
			</MenuList>
		</>
	);
};

export default Menu;

import CompanyCard from 'components/company-card/CompanyCard';
import { Skeleton, Grid, Box } from '@mui/material';
import { Masonry } from '@mui/lab';
import { useQuery } from 'react-query';
import { getMyCompanies } from 'queries/companies';

const skeletons = ['', ''];

const CompanyList = () => {
	const query = useQuery('my-companies', getMyCompanies);

	if (query.isLoading) {
		return (
			<>
				<h1>Company List</h1>
				{skeletons.map((_, index) => (
					<Grid
						key={`company-skeleton-${index}`}
						container
						spacing={4}
						sx={{ marginTop: '25px' }}
					>
						<Grid item sm={4}>
							<Skeleton variant="rectangular" width="100%" height={200} />
						</Grid>
						<Grid item sm={8} zeroMinWidth>
							<Skeleton width="75%" height={40} />
							<Skeleton width={150} height={75} />
						</Grid>
					</Grid>
				))}
			</>
		);
	}

	return (
		<Box flex={1}>
			<h1>Company List</h1>
			<Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
				{(query.data ?? []).map((company, index) => (
					<CompanyCard key={index} {...company} />
				))}
			</Masonry>
		</Box>
	);
};

export default CompanyList;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { request } from 'utils/axios';
import { Company, MyCompaniesResponse } from 'types/company';
import { Reward } from 'types/reward';
import { Survey } from 'types/surveys';

export const getCompanyById = async ({ queryKey }: any): Promise<Company> => {
	const [_key, companyId] = queryKey;
	return await request(`/companies/${companyId}`);
};

export const getMyCompanies = async (): Promise<MyCompaniesResponse> =>
	await request('/my-companies');

export const getCompanies = async (): Promise<Company[]> => await request('/companies');

export const updateCompany = async (payload: Company) => {
	const body: Partial<Company> = { ...payload };

	delete body._id;

	return await request.put(`/companies/${payload._id}`, body);
};

export const getUserCompanies = async ({ queryKey }: any): Promise<Company[]> => {
	const [_, companyIdList] = queryKey;
	return await request.post('/users/companies', companyIdList);
};

export type CompanyDetailsPayload = Pick<
	Company,
	'name' | 'alias' | 'pricePerPoint' | 'image' | 'logo' | 'website' | 'phone'
>;

export const updateCompanyDetails = async ({
	id,
	payload,
}: {
	id: string;
	payload: CompanyDetailsPayload;
}) => await request.put(`/companies/${id}`, payload);

export const updateCompanyRewards = async ({
	id,
	payload,
}: {
	id: string;
	payload: Reward[];
}) => await request.put(`/companies/${id}/rewards`, payload);

export const updateCompanySurvey = async ({
	companyId,
	survey,
}: {
	companyId: string;
	survey: Survey;
}) => {
	const body: Partial<Survey> = { ...survey };
	delete body._id;
	await request.put(`/companies/${companyId}/surveys/${survey._id}`, body);
};

export const addCompanySurvey = async ({
	companyId,
	survey,
}: {
	companyId: string;
	survey: Pick<Survey, 'title' | 'points' | 'estimatedTime'>;
}): Promise<Company> => {
	return await request.post(`/companies/${companyId}/surveys`, survey);
};

export const deleteCompanySurvey = async ({
	companyId,
	surveyId,
}: {
	companyId: string;
	surveyId: string;
}): Promise<Company> => {
	return await request.delete(`/companies/${companyId}/surveys/${surveyId}`);
};

import { useEffect, useRef } from 'react';

const useRenderControl = () => {
	const count = useRef(0);

	useEffect(() => {
		count.current++;
	}, []);

	return {
		isFirstRender: count.current === 0,
	};
};

export default useRenderControl;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Company } from 'types/company';
import { Point } from 'types/point';
import { request } from 'utils/axios';

export const createPoints = async (
	redeemCode: unknown
): Promise<{
	createdPoints: number;
	company: Company;
}> => await request.post(`/points/${redeemCode}`);

export const getUserPoints = async ({ queryKey }: any): Promise<{ points: number }> => {
	const [_key, companyId, userId] = queryKey;
	return await request.get(`/points/${companyId}/${userId}`);
};

export const redeemUserPoints = async ({ queryKey }: any) => {
	const [_key, payload] = queryKey;
	return await request.patch(`/points`, payload);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Stack,
	Typography,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Survey } from 'types/surveys';
import { ArrowForward } from '@mui/icons-material';
import { companySurveyUrl } from 'constants/urls';

interface SurveyCardProps extends Survey {}

const SurveyCard = ({ title, description, image, _id, ...rest }: SurveyCardProps) => {
	const { companyId } = useParams();
	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'row',
				padding: 0,
			}}
		>
			<CardMedia
				component="img"
				alt={description}
				sx={{ width: 250 }}
				image={image ?? '/placeholder.png'}
			/>
			<Stack
				gap={2}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					padding: 3,
					paddingLeft: 0,
				}}
			>
				<CardContent sx={{ flex: '1 0 auto' }}>
					<Typography component="div" variant="h5">
						{title}
					</Typography>
					<Typography variant="subtitle1" color="text.secondary" component="div">
						{description}
					</Typography>
					<Stack>
						<Typography variant="caption" color="text.secondary" component="div">
							{description}
						</Typography>
						<Typography variant="subtitle1" color="text.secondary" component="div">
							{description}
						</Typography>
					</Stack>
				</CardContent>
				<CardActions>
					<Button
						component={Link}
						size="small"
						color="primary"
						to={companySurveyUrl(companyId, _id)}
						endIcon={<ArrowForward />}
					>
						Iniciar
					</Button>
				</CardActions>
			</Stack>
		</Card>
	);
};

export default SurveyCard;

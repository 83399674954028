import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Stack, Typography, Button as MuiButton, InputAdornment } from '@mui/material';
import {
	EmailOutlined as EmailOutlinedIcon,
	LockOpenOutlined as LockOpenOutlinedIcon,
} from '@mui/icons-material';
import { User } from 'types/user';
import { Button, TextInput, PasswordMatching, PasswordValidator } from 'components';
import { useMutation } from 'react-query';
import { createUser } from 'queries';

const ValidationSchema = Yup.object().shape({
	email: Yup.string()
		.email('Correo no valido')
		.required('Requerido')
		.required('Campo requerido'),
	name: Yup.string().min(2, 'Muy corto').max(30, 'Muy Largo').required('Campo requerido'),
	lastName: Yup.string()
		.min(2, 'Muy corto')
		.max(30, 'Muy Largo')
		.required('Campo requerido'),
	password: Yup.string().min(2, 'Muy corto').max(30, 'Muy Largo').required('Requerido'),
});

interface SignUpForm extends User {
	repeatPassword: string;
}

const SignUp = () => {
	const userMutation = useMutation(createUser);

	const onSubmit = (values: SignUpForm) => {
		delete values.role;
		userMutation.mutate(values);
	};

	return (
		<>
			<Formik
				initialValues={{
					name: '',
					lastName: '',
					email: '',
					password: '',
					repeatPassword: '',
					companyId: '6373c76970f4a50040609216',
				}}
				validationSchema={ValidationSchema}
				onSubmit={onSubmit}
			>
				{({ values }) => (
					<Form>
						<Stack spacing={4}>
							<Typography variant="h2" component="h1">
								Crear Cuenta
							</Typography>
							<Stack spacing={2}>
								<TextInput name="name" label="Nombre" />
								<TextInput name="lastName" label="Apellido" />
								<TextInput
									name="email"
									label="Correo"
									type="email"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<EmailOutlinedIcon />
											</InputAdornment>
										),
									}}
								/>
								<Stack spacing={2} direction="row">
									<Stack spacing={2}>
										<TextInput
											name="password"
											type="password"
											label="Contraseña"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<LockOpenOutlinedIcon />
													</InputAdornment>
												),
											}}
										/>
										<PasswordValidator password={values.password as string} />
									</Stack>
									<Stack spacing={2}>
										<TextInput
											name="repeatPassword"
											type="password"
											label="Repetir Contraseña"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<LockOpenOutlinedIcon />
													</InputAdornment>
												),
											}}
										/>
										<PasswordMatching
											password={values.password as string}
											repeatPassword={values.repeatPassword}
										/>
									</Stack>
								</Stack>
							</Stack>
							<Stack spacing={2}>
								<Button type="submit" isLoading={userMutation.isLoading}>
									Crear Cuenta
								</Button>
								<MuiButton
									component={Link}
									to="/iniciar-sesion"
									type="text"
									variant="text"
									color="secondary"
								>
									Iniciar Sesión
								</MuiButton>
							</Stack>
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default SignUp;

import { Box, Divider, Typography } from '@mui/material';

interface IBoxInfo {
	title: any;
	subtitle: string;
}

const BoxInfo = ({ title, subtitle }: IBoxInfo) => {
	return (
		<Box
			sx={{
				border: 2,
				borderRadius: 4,
				borderColor: 'lightgray',
				width: 110,
				height: 90,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography fontSize={24} sx={{ p: 1, textAlign: 'center' }}>
				{title}
			</Typography>
			<Divider />
			<Typography
				variant="h4"
				sx={{ p: 1, textAlign: 'center', textTransform: 'uppercase' }}
			>
				{subtitle}
			</Typography>
		</Box>
	);
};

export default BoxInfo;

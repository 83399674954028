/* eslint-disable @typescript-eslint/no-unused-vars */
import { SignInResponse } from 'types/api';
import { User } from 'types/user';
import { request } from 'utils/axios';

export const signin = async (payload: any): Promise<SignInResponse> =>
	await request.post('/signin', payload);

export const signout = async () =>
	await request.post('/signout', {
		refreshToken: localStorage.getItem('refreshToken'),
	});

export const changePassword = async (payload: any) =>
	await request.patch('/change-password', payload);

export const verifyEmail = async (
	emailToken: string
): Promise<{
	message: string;
	user: User;
}> => {
	return await request.post(`/verify-email/${emailToken}`);
};
